import { PropsWithChildren } from "react";
import { AuthProvider } from "./auth";
import { FriendsProvider } from "./friends";
import { PlanProvider } from "./plan";
import { QueueProvider } from "./queue";
import { UserProvider } from "./user";

export function ApiProvider({ children }: PropsWithChildren) {
  return (
    <AuthProvider>
      <UserProvider>{children}</UserProvider>
    </AuthProvider>
  );
}

export function AuthApiProvider({ children }: PropsWithChildren) {
  return (
    <FriendsProvider>
      <QueueProvider>
        <PlanProvider>{children}</PlanProvider>
      </QueueProvider>
    </FriendsProvider>
  );
}
