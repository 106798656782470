import { StarFilled } from "@ant-design/icons";
import {
  AutoComplete,
  Flex,
  Form,
  Input,
  Slider,
  Space,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import { ActivityType } from "../../api/queue";
import { activitesCategory, activitiesData } from "./activities";
import { required } from "./rules";

const { Text } = Typography;

export const ActivityDetailsInput = ({ disabled }: { disabled?: boolean }) => (
  <Form.Item<ActivityType>
    name="details"
    rules={[required]}
    label="Activity Details"
  >
    <Input.TextArea disabled={disabled} placeholder="" rows={3} />
  </Form.Item>
);

export function GroupSizeInput() {
  return (
    <Form.Item<ActivityType>
      name="groupSize"
      label="How many people are you looking for? (optional)"
    >
      <Slider
        tooltip={{
          formatter: (x) => {
            if (x === 2) {
              return "Any";
            }
            return x;
          },
        }}
        min={2}
        max={6}
        step={null}
        marks={{
          2: "Any",
          3: "3",
          4: "4",
          5: "5",
          6: "6",
        }}
      />
    </Form.Item>
  );
}

export function ActivityInput({ disabled }: { disabled?: boolean }) {
  const renderTitle = (title: string) => (
    <Flex justify="space-between">
      <Text type="secondary">{title}</Text>
    </Flex>
  );

  const renderItem = (title: string, count: string) => ({
    value: title,
    label: (
      <Flex justify="space-between">
        <Text>{title}</Text>
        <Space>
          {count}
          <StarFilled />
        </Space>
      </Flex>
    ),
  });
  const [input, setInput] = useState<string>("");
  const options = activitesCategory
    .map(({ category, activities }) => ({
      label: renderTitle(category),
      options: activities
        .map((a) => activitiesData[a])
        .filter((a) => a)
        .filter(
          (a) =>
            input === "" ||
            (a && a.activity.toLowerCase().includes(input.toLowerCase())),
        )
        .map((a) => renderItem(a.activity, a.rating)),
    }))
    .filter((group) => group.options.length > 0);

  const form = Form.useFormInstance();
  return (
    <Form.Item<ActivityType>
      rules={[required]}
      name="activity"
      label="What category does this hangout fall into?"
      /*
      label="What kind of things are you looking to do?"
      */
    >
      <AutoComplete
        onSelect={(value) => {
          console.log(value);
          form.setFieldValue("details", activitiesData[value].description);
        }}
        disabled={disabled}
        allowClear
        onChange={(e) => {
          form.setFieldValue("details", "");
          setInput(e);
        }}
        style={{ width: "100%" }}
        options={options}
      >
        <TextArea
          size="large"
          placeholder="Hiking"
          rows={1}
          disabled={disabled}
        />
      </AutoComplete>
    </Form.Item>
  );
}
