import {
  EllipsisOutlined,
  InfoCircleOutlined,
  LoginOutlined,
  SendOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  ConfigProvider,
  Drawer,
  Flex,
  Popover,
  Space,
  theme,
  Typography,
} from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../api/firebaseConfig";
import { PlanType } from "../api/plan";
import { activities, AvailableActivity } from "./Form/QueueInput";
import { FriendsLine } from "./Friends";

const { Paragraph } = Typography;

type PlanProps = { plan: PlanType };

export const PlanCard: React.FC<{ plan: PlanType }> = ({
  plan,
}: {
  plan: PlanType;
}) => {
  const joined = (plan.participants || []).some(
    (p: string) => auth.currentUser?.uid === p,
  );

  const { activity } = plan;
  const activityDetails = activities[activity as AvailableActivity];
  const title = activityDetails.description;
  return (
    <Card
      style={{ width: 300 }}
      cover={<></>}
      actions={[
        <EventInfoButton plan={plan} />,
        joined ? (
          <MessageButton plan={plan} />
        ) : (
          <JoinQueueButton plan={plan} />
        ),
      ]}
    >
      <Space direction="vertical">
        <PlanHeader plan={plan} />
        <Paragraph>{title}</Paragraph>
        <Typography.Text strong>Participants:</Typography.Text>

        {plan.participants.map((p) => (
          <FriendsLine profileId={p} key={p} />
        ))}
      </Space>
    </Card>
  );
};

// Sub-components
export const EventInfoButton = ({
  plan,
  buttonAlgorithm = theme.defaultAlgorithm,
}: {
  buttonAlgorithm?: any;
  plan: PlanType;
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { activity } = plan;
  const activityDetails = activities[activity as AvailableActivity];
  const title = "TODO";
  const details = activityDetails.description;
  return (
    <>
      <ConfigProvider
        theme={{
          algorithm: buttonAlgorithm,
        }}
      >
        <Button
          key="info"
          onClick={() => setDrawerOpen(true)}
          shape="circle"
          type="text"
          icon={<InfoCircleOutlined />}
        />
      </ConfigProvider>
      <Drawer
        title={title}
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        size="large"
      >
        <Space direction="vertical">
          <Typography.Text strong>Who's joining?</Typography.Text>
          <p>{details}</p>
          {plan.participants.map((p) => (
            <FriendsLine profileId={p} key={p} />
          ))}
        </Space>
      </Drawer>
    </>
  );
};
const PlanHeader = ({ plan }: PlanProps) => {
  const { activity } = plan;
  const activityDetails = activities[activity as AvailableActivity];
  // <Avatar icon={<UserOutlined />} src={null} />
  return (
    <Space>
      <Flex vertical>
        <Typography.Text strong>{activityDetails.text}</Typography.Text>
        <Typography.Text type="secondary" style={{ margin: 0 }}>
          {plan.date.toString()}
        </Typography.Text>
      </Flex>
    </Space>
  );
};
const MessageButton = ({ plan }: PlanProps) => {
  const navigate = useNavigate();
  return (
    <Button
      type="text"
      key="message"
      onClick={() => navigate(`/hangout/${plan.id}`)}
    >
      <SendOutlined />
    </Button>
  );
};
const JoinQueueButton = ({ plan }: PlanProps) => (
  <Button type="text" key="message" onClick={() => plan.join()}>
    <LoginOutlined />
  </Button>
);

// eslint-disable-next-line
const ExitGroupButton = () => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Popover
      content={
        <Space.Compact direction="vertical" style={{ width: "100%" }}>
          <Button type="primary" danger>
            Leave Group
          </Button>
          <Button danger>Report group</Button>
        </Space.Compact>
      }
      trigger="click"
      open={open}
      onOpenChange={(open) => setOpen(open)}
    >
      <Button type="text">
        <EllipsisOutlined key="ellipsis" />
      </Button>
    </Popover>
  );
};
