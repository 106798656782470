import {
  AntDesignOutlined,
  LeftOutlined,
  SmileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Flex,
  Form,
  Input,
  Space,
  theme,
  Tooltip,
  Typography,
} from "antd";
import { activities, AvailableActivity } from "component/Form/QueueInput";
import {
  addDoc,
  collection,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { useContext, useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { auth, db } from "./api/firebaseConfig";
import { FriendsContext } from "./api/friends";
import { PlanContext, PlanType } from "./api/plan";
import { Container } from "./component/Container";
import { EventInfoButton } from "./component/Plan";

const { Title, Paragraph, Text } = Typography;

type Message = {
  id?: string;
  message: string;
  user: string;
  time?: string | Timestamp;
  roomId: string;
};

export default function MessagePage() {
  const { id: roomId } = useParams();
  const [messages, setMessages] = useState<Message[]>([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const plans = useContext(PlanContext);
  const plan = plans.plan!.find((p: PlanType) => p.id === roomId);
  const activity = activities[plan?.activity as AvailableActivity];

  const messageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const q = query(
      collection(db, "messages"),
      where("roomId", "==", roomId),
      orderBy("time"),
    );
    return onSnapshot(q, (querySnapshot) => {
      const messages: Message[] = [];
      querySnapshot.forEach((doc) =>
        messages.push({ ...doc.data(), id: doc.id } as Message),
      );
      setMessages(messages);
    });
  }, [roomId]);

  if (!roomId) {
    return <Navigate to="/" />;
  }

  return (
    <Container
      lessPadding
      header={
        <Flex align="center" gap={10} style={{ width: "100%" }}>
          <Button
            icon={<LeftOutlined style={{ color: "white" }} />}
            type="text"
            onClick={() => navigate("/")}
          />
          <Avatar.Group
            maxCount={2}
            size="small"
            maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
          >
            <Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=2" />
            <Avatar style={{ backgroundColor: "#f56a00" }}>K</Avatar>
            <Tooltip title="Ant User" placement="top">
              <Avatar
                style={{ backgroundColor: "#87d068" }}
                icon={<UserOutlined />}
              />
            </Tooltip>
            <Avatar
              style={{ backgroundColor: "#1677ff" }}
              icon={<AntDesignOutlined />}
            />
          </Avatar.Group>

          <Title
            level={5}
            style={{ color: "white", margin: 0, flex: 1 }}
            ellipsis={{ rows: 1 }}
          >
            {activity.text} on {plan?.date.toString()}
          </Title>
          <EventInfoButton
            plan={plan as unknown as PlanType}
            buttonAlgorithm={theme.darkAlgorithm}
          />
        </Flex>
      }
      filledHeader
      content={
        <Flex
          gap={20}
          vertical
          style={{ overflow: "scroll", height: "100%", padding: "20px 0" }}
          ref={messageRef}
        >
          {messages.map((message) => (
            <MessageRow key={message.id} message={message} />
          ))}
        </Flex>
      }
      footer={
        <Form
          onFinish={({ message }) => {
            const doc: Message = {
              message,
              user: auth.currentUser!.uid,
              time: Timestamp.now(),
              roomId,
            };
            addDoc(collection(db, "messages"), doc);
            form.setFieldValue("message", "");
            messageRef.current!.scrollTo(0, messageRef.current!.scrollHeight);
          }}
          form={form}
        >
          <Space.Compact block size="large">
            <Form.Item noStyle name="message">
              <Input
                prefix={
                  <Button type="text" size="small">
                    <SmileOutlined />
                  </Button>
                }
                placeholder="Write a message..."
              />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Space.Compact>
        </Form>
      }
    />
  );
}

const MessageRow = ({ message }: { message: Message }) => {
  const fromMe = message.user === auth.currentUser?.uid;
  const friends = useContext(FriendsContext);
  const sender = friends[message.user];
  return (
    <Flex vertical align={fromMe ? "end" : "start"}>
      <Flex
        style={{
          margin: "0 20",
          flexDirection: fromMe ? "row-reverse" : "row",
        }}
        align="end"
        gap={5}
      >
        <Avatar icon={<UserOutlined />} />
        <Card style={{ flex: 1 }} styles={{ body: { paddingBottom: 10 } }}>
          <Text strong>{sender?.preferredName || "Unknowned user"}</Text>
          <Paragraph>{message.message}</Paragraph>
          <Flex justify="end">
            <Text disabled>Sent at </Text>
            <Text disabled>
              {
                // TODO:fix this lol
                (message.time as Timestamp).toDate().toLocaleString()
              }
            </Text>
          </Flex>
        </Card>
      </Flex>
    </Flex>
  );
};
