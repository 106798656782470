import { doc, getDoc } from "firebase/firestore";
import { createContext, useState } from "react";
import { db } from "./firebaseConfig";
import { Profile } from "./user";

export const FriendsContext = createContext<{ [key: string]: Profile }>({});

function getFriend(uid: string) {
  return getDoc(doc(db, "users", uid)).then((querySnapshot) => {
    if (!querySnapshot.exists()) {
      return false
    }
    return querySnapshot.data();
  });
}

export function FriendsProvider({ children }: React.PropsWithChildren) {
  const [profileData, setProfileData] = useState<any>({});
  const profileProxy = new Proxy(profileData, {
    get(target, name: string) {
      if (name in target) {
        return target[name];
      }
      getFriend(name).then((data) =>
        setProfileData((prev: {}) => ({ ...prev, [name]: data })),
      );
    },
  });
  console.log(profileData);

  return (
    <FriendsContext.Provider value={profileProxy}>
      {children}
    </FriendsContext.Provider>
  );
}
