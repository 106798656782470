import { UserOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Image,
  Layout,
  Space,
  theme,
  Tooltip,
  Typography,
} from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import React, { ReactElement } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../logo.png";

const { Header, Content, Footer } = Layout;

type ContainerLayout = {
  header: React.ReactNode;
  content: React.ReactNode;
  footer: React.ReactNode;
  headerAlign?: "center" | "flex-end";
  filledHeader?: boolean;
  lessPadding?: boolean;
};

export function Container(props: ContainerLayout) {
  const {
    token: { colorBgLayout },
  } = theme.useToken();

  return (
    <Layout style={{ height: "100%" }} id="container">
      <Header
        style={{
          display: "flex",
          height: "72px",
          alignItems: props.headerAlign || "center",
          justifyContent: "space-between",
          padding: props.filledHeader ? 5 : undefined,
          backgroundColor: props.filledHeader ? undefined : colorBgLayout,
        }}
      >
        {props.header}
      </Header>
      <Content
        style={{
          padding: props.lessPadding ? "0 24px" : "0 48px",
          marginBottom: "1px",
          flex: 1,
          overflow: "scroll",
        }}
      >
        {props.content}
      </Content>
      <Divider style={{ margin: 0 }} />
      <Footer
        style={{
          backgroundColor: colorBgLayout,
          zIndex: 1,
          padding: props.lessPadding ? "12px" : "24px 24px",
          textAlign: "center",
        }}
      >
        {props.footer}
      </Footer>
    </Layout>
  );
}
export function DefaultHeader({ action }: { action?: ReactElement }) {
  return (
    <>
      <Logo />
      {action || <div />}
    </>
  );
}
export function ProfileAction() {
  const navigate = useNavigate();
  return (
    <Tooltip title="What is GV?">
      <Button
        shape="circle"
        icon={<UserOutlined />}
        onClick={() => navigate("/profile")}
      />
    </Tooltip>
  );
}

export function Logo({ size = "middle" }: { size?: SizeType }) {
  return (
    <Link
      to="/"
      style={{
        padding: 0,
        fontFamily: "TTNormsPro",
        fontSize: 20,
        lineHeight: "initial",
        boxShadow: "none",
      }}
    >
      <Space>
        <Image
          src={logo}
          height={40}
          width={40}
          style={{ margin: 0 }}
          preview={false}
        />
        {size === "small" ? null : (
          <Typography.Title style={{ margin: 0 }} level={4}>
            Jigsaur
          </Typography.Title>
        )}
      </Space>
    </Link>
  );
}
