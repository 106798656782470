import { useContext } from "react";
import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import { AuthApiProvider } from "./api";
import { AuthContext } from "./api/auth";
import { UserContext } from "./api/user";
import CreateProfile from "./CreateProfile";
import CreateQueue from "./CreateQueue";
import Home from "./HomePage";
import LandingPage from "./LandingPage";
import Login from "./Login";
import MessagePage from "./MessagePage";
import PrivacyPage from "./PrivacyPage";
import ProfilePage from "./ProfilePage";
import CreatePlan from "./screens/Admin/CreatePlan";
import TnCPage from "./TnC";
import ProfileDev from "ProfileDev";

function AuthWrapper() {
  const authUser = useContext(AuthContext);
  if (authUser?.user) {
    return <Outlet />;
  }
  return <Navigate to="/login" />;
}

function ProfileWrapper() {
  const userProfile = useContext(UserContext);
  console.log(userProfile);
  if (!userProfile) {
    return <Navigate to="/createProfile" />;
  }
  return (
    <AuthApiProvider>
      <Outlet />
    </AuthApiProvider>
  );
}
function IfAuth({
  loggedIn,
  loggedOut,
}: {
  loggedIn: JSX.Element;
  loggedOut: JSX.Element;
}): JSX.Element {
  const authUser = useContext(AuthContext);
  if (authUser?.user) {
    return loggedIn;
  }
  console.log(authUser);
  return loggedOut;
}

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <IfAuth loggedIn={<Navigate to="/home" />} loggedOut={<LandingPage />} />
    ),
  },
  {
    path: "/privacy",
    element: <PrivacyPage />,
  },
  {
    path: "/terms",
    element: <TnCPage />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  { element: <ProfileDev />, path: "profileDev" },
  {
    element: <AuthWrapper />,
    children: [
      {
        element: <ProfileWrapper />,
        children: [
          { element: <CreateQueue />, path: "createQueue" },
          { element: <Home />, path: "home" },
          { element: <MessagePage />, path: "hangout/:id" },
          { element: <ProfilePage />, path: "profile/:id?" },
          { element: <CreatePlan />, path: "admin" },
        ],
      },
      { element: <CreateProfile />, path: "/createProfile" },
    ],
  },
]);
