import {
  addDoc,
  collection,
  onSnapshot,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { createContext, useEffect, useState } from "react";
import { Status } from "./auth";
import { auth, db } from "./firebaseConfig";
import { FilterOptions } from "./queue";
import {Dayjs} from "dayjs";
import Loader from "component/Loader";

export type PlanType = {
  id: string;
  join: Function;
  participants: string[];
  // creator: string;
  time: "afternoon";
  date: Timestamp | Dayjs;
  options?: FilterOptions;
  activity: string;
};

export function createPlan(plan: Omit<PlanType, "id" | "join">) {
  const user = auth.currentUser;
  if (!user) {
    throw Error();
  }

  return addDoc(collection(db, "plan"), plan);
}

export function getUserPlanSnapshot(success: (p: PlanType[]) => void) {
  const q = query(
    collection(db, "plan"),
    where("date", ">", Timestamp.now()),
    where("participants", "array-contains", auth.currentUser?.uid),
  );
  return onSnapshot(q, (querySnapshot) => {
    const plan: PlanType[] = [];
    querySnapshot.forEach((doc) =>
      plan.push({ ...doc.data(), id: doc.id } as PlanType),
    );
    success(plan);
  });
}

function getAllPlanSnapshot(success: (p: PlanType[]) => void) {
  const q = query(collection(db, "plan"), where("date", ">", Timestamp.now()));
  return onSnapshot(q, (querySnapshot) => {
    const plan: PlanType[] = [];
    querySnapshot.forEach((doc) =>
      plan.push({ ...doc.data(), id: doc.id } as PlanType),
    );
    success(plan);
  });
}

export const PlanContext = createContext<Status<{ plan?: PlanType[] }>>({
  loading: true,
  error: false,
});

export function PlanProvider({ children }: { children: React.ReactNode }) {
  const [plan, setPlan] = useState<Status<{ plan?: PlanType[] }>>({
    loading: true,
    error: false,
  });
  useEffect(() => {
    return getAllPlanSnapshot((plan: PlanType[]) =>
      setPlan({ plan, loading: false, error: false }),
    );
  }, []);

  console.log(plan);
  if (plan.loading) {
    return (
      <Loader />
    );
  }

  return <PlanContext.Provider value={plan}>{children}</PlanContext.Provider>;
}

