import {
  CheckCircleTwoTone,
  DeleteOutlined,
  EllipsisOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Card, Flex, Popover, Space, Typography } from "antd";
import { signOut } from "firebase/auth";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { auth } from "./api/firebaseConfig";
import { FriendsContext } from "./api/friends";
import { Profile, UserContext } from "./api/user";
import { Container, DefaultHeader } from "./component/Container";
import { goals } from "./component/Form/ProfileInput";

export default function ProfilePage() {
  const { id } = useParams();
  const { profile: selfProfile } = useContext(UserContext);
  const friends = useContext(FriendsContext);
  const profile = (id ? friends[id] : selfProfile) || {};
  console.log(selfProfile);

  return (
    <Container
      header={<DefaultHeader action={<ProfileHeaderAction />} />}
      content={
        <Flex vertical align="stretch" style={{ padding: "100px 0" }} gap={20}>
          <Flex vertical align="center" gap={20}>
            <Avatar
              icon={<UserOutlined />}
              size={150}
              src={profile.profilePic}
            />
            <Name profile={profile} />
            <ThreeButtons profile={profile} />
          </Flex>
          <Flex vertical align="stretch" gap={20}>
            <AboutMe profile={profile} />
            <Goals profile={profile} />
            <Button danger icon={<DeleteOutlined />}>
              Delete Account
            </Button>
          </Flex>
        </Flex>
      }
      footer={null}
    />
  );
}
type ProfileProps = {
  profile: Profile;
};

/* =====================
 * COMPONENTS
 * =====================
 */
const ProfileHeaderAction = () => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Popover
      content={
        <Space.Compact direction="vertical" style={{ width: "100%" }}>
          <Button
            type="primary"
            danger
            icon={<LogoutOutlined />}
            onClick={() => signOut(auth)}
          >
            Logout
          </Button>
        </Space.Compact>
      }
      trigger="click"
      open={open}
      onOpenChange={(open) => setOpen(open)}
    >
      <Button shape="circle">
        <EllipsisOutlined key="ellipsis" />
      </Button>
    </Popover>
  );
};
export const Name = ({ profile }: ProfileProps) => (
  <Space direction="vertical" align="center">
    <Typography.Title style={{ margin: 0, textAlign: "center" }}>
      {profile?.firstName} {profile?.lastName}{" "}
      {profile?.verified ? <CheckCircleTwoTone /> : null}
    </Typography.Title>
  </Space>
);

const ThreeButtons = ({ profile }: ProfileProps) => (
  <></>
  /*
  <ConfigProvider
    theme={{
      components: {
        Button: {
          defaultBorderColor: "blue",
          defaultColor: "blue",
          colorPrimary: "blue",
        },
      },
    }}
  >
    <Button block icon={<CheckCircleTwoTone />}>
      Verify {profile.firstName}
    </Button>
  </ConfigProvider>
  <Space align="center">
    <Button type="primary" size="large">
    <Button type="primary" icon={<FireFilled />} size="large" danger />
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: "blue",
          },
        },
      }}
    >
      <Button type="primary" icon={<FireFilled />} size="large" />
    </ConfigProvider>
  </Space>
   */
);

const AboutMe = ({ profile }: ProfileProps) => (
  <Card title="About me">
    <Typography.Paragraph>{profile?.aboutMe}</Typography.Paragraph>
  </Card>
);

const Goals = ({ profile }: ProfileProps) =>
  profile.goal ? (
    <Card title="Goal">
      <Space direction="vertical">
        {Object.entries(profile.goal).filter(([_, selected]) => selected).map(([g, _]) => (
          <Card key={g}>
            <Typography.Text>{goals[g as keyof typeof goals]}</Typography.Text>
          </Card>
        ))}
      </Space>
    </Card>
  ) : null;

