import { CheckCircleTwoTone, UserOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { FriendsContext } from "../api/friends";

export const FriendsLine = ({ profileId }: { profileId: string }) => {
  const profiles = useContext(FriendsContext);
  return (
    <Space key={profileId}>
      <UserOutlined />
      <Link to={`/profile/${profileId}`}>
        {profiles[profileId]?.preferredName || "Unknown user"}
        {profiles[profileId]?.verified ? <CheckCircleTwoTone /> : null}
      </Link>
    </Space>
  );
};
