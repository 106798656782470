import { ConfigProvider } from "antd";
import { createContext, Dispatch, SetStateAction, useState } from "react";
import { RouterProvider } from "react-router-dom";
import { ApiProvider } from "api";
import { router } from "routerConfig";

export const AppWideLoading = createContext<{
  setLoading: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
} | null>(null);

function App() {
  const [loading, setLoading] = useState(false);
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#A8815B",
        },
      }}
    >
      <AppWideLoading.Provider value={{ loading, setLoading }}>
        <ApiProvider>
          <RouterProvider router={router} />
        </ApiProvider>
      </AppWideLoading.Provider>
    </ConfigProvider>
  );
}

export default App;
