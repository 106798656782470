export const activitesCategory = [
  {
    category: "Casual Eating and Drinking",
    activities: [
      "Coffee at a local café",
      "Brunch at a popular spot",
      "Trying out a new restaurant",
      "Attending a cooking class",
      "Doing a wine or beer tasting",
    ],
    emoji: "🍽️",
  },
  {
    category: "Outdoor and Nature Activities",
    activities: [
      "Hiking in a local park",
      "Taking a bike ride",
      "Kayaking or canoeing",
      "Visiting a botanical garden",
      "Going to the beach or pool",
      "Picnicking in a park",
      "Stargazing at an observatory or a quiet spot",
    ],
    emoji: "🌳",
  },
  {
    category: "Arts and Culture",
    activities: [
      "Visiting a museum",
      "Going to an art gallery",
      "Seeing a play or musical",
      "Attending a poetry reading or open mic night",
      "Visiting a historic site or landmark",
      "Going to a themed café or restaurant",
      "Taking a photography walk",
    ],
    emoji: "🎨",
  },
  {
    category: "Active and Sports",
    activities: [
      "Attending a yoga class",
      "Going for a run or jog",
      "Playing mini-golf",
      "Bowling",
      "Going ice skating or roller skating",
      "Taking a dance class",
      "Attending a local sporting event",
      "Visiting an indoor climbing gym",
      "Participating in a local race or marathon",
    ],
    emoji: "⛹️‍♂️",
  },
  {
    category: "Social and Interactive Games",
    activities: [
      "Playing board games or video games",
      "Doing a puzzle together",
      "Attending a trivia night at a bar or pub",
      "Trying out escape rooms",
    ],
    emoji: "🎲",
  },
  {
    category: "Creative and Crafty",
    activities: [
      "Crafting (e.g., knitting, pottery)",
      "Painting or drawing",
      "Doing a DIY project for home decor",
    ],
    emoji: "🎨",
  },
  {
    category: "Entertainment and Festivities",
    activities: [
      "Watching a movie in the cinema",
      "Going to a concert",
      "Exploring an amusement park",
      "Attending a festival or fair",
      "Going to a karaoke bar",
      "Going on a ghost tour",
    ],
    emoji: "🎉",
  },
  {
    category: "Shopping and Exploring",
    activities: [
      "Exploring a farmer's market",
      "Shopping at a flea market or antique shop",
      "Exploring a new neighborhood in your city",
    ],
    emoji: "🛍️",
  },
  {
    category: "Learning and Growth",
    activities: [
      "Participating in a workshop or seminar",
      "Joining a book club",
      "Volunteering at a community event",
    ],
    emoji: "📚",
  },
];
export const activitiesData: { [key: string]: any } = {
  "Coffee at a local café": {
    group: "Casual Eating and Drinking",
    activity: "Coffee at a local café",
    rating: "10",
    reasoning: "Low pressure, easy to talk",
    emoji: "☕️",
  },
  "Brunch at a popular spot": {
    group: "Casual Eating and Drinking",
    activity: "Brunch at a popular spot",
    rating: "9",
    reasoning: "Relaxed, good conversation setting",
    emoji: "🍳",
    description:
      "Sharing a leisurely mid-morning meal at a well-liked brunch locale, offering a chance for extended conversation and a variety of foods.",
  },
  "Trying out a new restaurant": {
    group: "Casual Eating and Drinking",
    activity: "Trying out a new restaurant",
    rating: "9",
    reasoning: "A classic get-to-know-you setting",
    emoji: "🍽️",
    description:
      "Dining at a restaurant for the first time, experiencing new flavors together, and sharing thoughts on the meal.",
  },
  "Attending a cooking class": {
    group: "Casual Eating and Drinking",
    activity: "Attending a cooking class",
    rating: "8",
    reasoning: "Interactive and fun",
    emoji: "🍳",
    description:
      "Learning to cook a specific type of cuisine together in a structured class, enhancing culinary skills and teamwork.",
  },
  "Doing a wine or beer tasting": {
    group: "Casual Eating and Drinking",
    activity: "Doing a wine or beer tasting",
    rating: "9",
    reasoning: "Fun, casual",
    emoji: "🍷",
    description:
      "Sampling different wines or beers at a vineyard, brewery, or special event, discussing preferences and learning about drink profiles.",
  },
  "Hiking in a local park": {
    group: "Outdoor and Nature Activities",
    activity: "Hiking in a local park",
    rating: "8",
    reasoning: "Great for deeper conversations",
    emoji: "🥾",
    description:
      "Walking through nature trails, enjoying the scenery and physical activity, while having quiet conversations.",
  },
  "Taking a bike ride": {
    group: "Outdoor and Nature Activities",
    activity: "Taking a bike ride",
    rating: "7",
    reasoning: "Less talk, more activity",
    emoji: "🚴",
    description:
      "Cycling together through designated paths or scenic areas, combining physical activity with casual interaction.",
  },
  "Kayaking or canoeing": {
    group: "Outdoor and Nature Activities",
    activity: "Kayaking or canoeing",
    rating: "6",
    reasoning: "Can be challenging for beginners",
    emoji: "🛶",
    description:
      "Paddling in kayaks or canoes on a river, lake, or sea, requiring coordination and offering tranquil moments surrounded by water.",
  },
  "Visiting a botanical garden": {
    group: "Outdoor and Nature Activities",
    activity: "Visiting a botanical garden",
    rating: "9",
    reasoning: "Beautiful, relaxing, good for chat",
    emoji: "🌺",
    description:
      "Strolling through gardens filled with various plants and flowers, a visually stimulating and calm environment for conversations.",
  },
  "Going to the beach or pool": {
    group: "Outdoor and Nature Activities",
    activity: "Going to the beach or pool",
    rating: "8",
    reasoning: "Relaxed, but involves swimwear",
    emoji: "🏖️",
    description:
      "Spending time swimming, sunbathing, or playing beach games, suitable for a relaxed or playful day out.",
  },
  "Picnicking in a park": {
    group: "Outdoor and Nature Activities",
    activity: "Picnicking in a park",
    rating: "10",
    reasoning: "Casual, great for conversations",
    emoji: "🧺",
    description:
      "Enjoying a meal outdoors in a park setting, offering a casual atmosphere for eating and chatting.",
  },
  "Stargazing at an observatory or a quiet spot": {
    group: "Outdoor and Nature Activities",
    activity: "Stargazing at an observatory or a quiet spot",
    rating: "9",
    reasoning: "Romantic, quiet",
    emoji: "🌌",
    description:
      "Observing stars through telescopes or from a dark spot, enjoying the majesty of the night sky while discussing the universe.",
  },
  "Visiting a museum": {
    group: "Arts and Culture",
    activity: "Visiting a museum",
    rating: "9",
    reasoning: "Lots of conversation starters",
    emoji: "🖼️",
    description:
      "Exploring exhibitions of art, history, science, or other subjects, which can spark insightful discussions.",
  },
  "Going to an art gallery": {
    group: "Arts and Culture",
    activity: "Going to an art gallery",
    rating: "9",
    reasoning: "Quiet, conducive to conversation",
    emoji: "🎨",
    description:
      "Viewing contemporary or classic artworks, discussing interpretations and impressions, fostering a creative dialogue.",
  },
  "Seeing a play or musical": {
    group: "Arts and Culture",
    activity: "Seeing a play or musical",
    rating: "4",
    reasoning: "Like a movie, limited interaction",
    emoji: "🎭",
    description:
      "Watching a live performance in a theatre, experiencing the art of drama or musical together.",
  },
  "Attending a poetry reading or open mic night": {
    group: "Arts and Culture",
    activity: "Attending a poetry reading or open mic night",
    rating: "7",
    reasoning: "Interesting, but quiet",
    emoji: "🎤",
    description:
      "Listening to poets or performers sharing their work, creating a reflective or inspirational environment.",
  },
  "Visiting a historic site or landmark": {
    group: "Arts and Culture",
    activity: "Visiting a historic site or landmark",
    rating: "9",
    reasoning: "Educational, interesting",
    emoji: "🏛️",
    description:
      "Touring places of historical significance, learning about the past and its impact on the present.",
  },
  "Going to a themed café or restaurant": {
    group: "Arts and Culture",
    activity: "Going to a themed café or restaurant",
    rating: "9",
    reasoning: "Unique, fun experience",
    emoji: "🎭",
    description:
      "Dining in a café or restaurant with a specific theme, which offers unique food and decor related to the theme.",
  },
  "Taking a photography walk": {
    group: "Arts and Culture",
    activity: "Taking a photography walk",
    rating: "9",
    reasoning: "Creative and leisurely",
    emoji: "📷",
    description:
      "Walking through an area with cameras, taking photos of interesting scenes or objects, enhancing photographic skills and sharing tips.",
  },
  "Attending a yoga class": {
    group: "Active and Sports",
    activity: "Attending a yoga class",
    rating: "5",
    reasoning: "Quiet, less interaction",
    emoji: "🧘",
    description:
      "Participating in a guided yoga session, focusing on physical poses and mental relaxation, suitable for stress relief and fitness.",
  },
  "Going for a run or jog": {
    group: "Active and Sports",
    activity: "Going for a run or jog",
    rating: "4",
    reasoning: "Hard to talk while running",
    emoji: "🏃",
    description:
      "Running together on a track, park, or through a neighborhood, fostering health and endurance.",
  },
  "Playing mini-golf": {
    group: "Active and Sports",
    activity: "Playing mini-golf",
    rating: "8",
    reasoning: "Fun and playful",
    emoji: "🏌️‍♂️",
    description:
      "Engaging in a light-hearted competition in miniature golf, which is fun and accessible for all skill levels.",
  },
  Bowling: {
    group: "Active and Sports",
    activity: "Bowling",
    rating: "9",
    reasoning: "Casual, easy to talk and play",
    emoji: "🎳",
    description:
      "Playing a game of bowling, an entertaining and competitive activity that allows for conversation between turns.",
  },
  "Going ice skating or roller skating": {
    group: "Active and Sports",
    activity: "Going ice skating or roller skating",
    rating: "8",
    reasoning: "Fun, but falls may occur",
    emoji: "⛸️",
    description:
      "Skating at an ice rink or on roller skates at a suitable venue, offering fun and a bit of physical challenge.",
  },
  "Taking a dance class": {
    group: "Active and Sports",
    activity: "Taking a dance class",
    rating: "7",
    reasoning: "Fun, but could be physically challenging",
    emoji: "💃",
    description:
      "Learning dance moves in a structured class environment, which can be lively and energetic, promoting physical health and coordination.",
  },
  "Attending a local sporting event": {
    group: "Active and Sports",
    activity: "Attending a local sporting event",
    rating: "7",
    reasoning: "Fun, but can be loud",
    emoji: "🏟️",
    description:
      "Watching a sports game, such as football, baseball, or basketball, which can be exciting and create a sense of camaraderie.",
  },
  "Visiting an indoor climbing gym": {
    group: "Active and Sports",
    activity: "Visiting an indoor climbing gym",
    rating: "7",
    reasoning: "Fun but physically demanding",
    emoji: "🧗‍♀️",
    description:
      "Climbing artificial rock walls, challenging oneself physically and mentally, suitable for adventurous spirits.",
  },
  "Participating in a local race or marathon": {
    group: "Active and Sports",
    activity: "Participating in a local race or marathon",
    rating: "5",
    reasoning: "Physical, not much talking",
    emoji: "🏁",
    description:
      "Running in a competitive event, whether for charity or personal achievement, promoting fitness and community involvement.",
  },
  "Playing board games or video games": {
    group: "Social and Interactive Games",
    activity: "Playing board games or video games",
    rating: "10",
    reasoning: "Fun, engaging, interactive",
    emoji: "🎮",
    description:
      "Engaging in strategic or fun games that require skill, luck, or teamwork, ideal for indoor entertainment and interaction.",
  },
  "Doing a puzzle together": {
    group: "Social and Interactive Games",
    activity: "Doing a puzzle together",
    rating: "9",
    reasoning: "Relaxed, collaborative",
    emoji: "🧩",
    description:
      "Assembling a jigsaw puzzle, which is a relaxing and collaborative activity that can be done at a leisurely pace.",
  },
  "Attending a trivia night at a bar or pub": {
    group: "Social and Interactive Games",
    activity: "Attending a trivia night at a bar or pub",
    rating: "8",
    reasoning: "Fun and competitive",
    emoji: "🧠",
    description:
      "Participating in a quiz on various topics, which can be fun and intellectually stimulating in a social environment.",
  },
  "Trying out escape rooms": {
    group: "Social and Interactive Games",
    activity: "Trying out escape rooms",
    rating: "9",
    reasoning: "Team-building, fun challenges",
    emoji: "🔐",
    description:
      "Solving puzzles and finding clues to escape from a themed room, great for teamwork and problem-solving skills.",
  },
  "Crafting (e.g., knitting, pottery)": {
    group: "Creative and Crafty",
    activity: "Crafting (e.g., knitting, pottery)",
    rating: "8",
    reasoning: "Creative, relaxed",
    emoji: "🧶",
    description:
      "Creating handcrafted items, which can be a soothing and rewarding way to spend time and learn new skills.",
  },
  "Painting or drawing": {
    group: "Creative and Crafty",
    activity: "Painting or drawing",
    rating: "8",
    reasoning: "Creative, relaxing",
    emoji: "🎨",
    description:
      "Expressing oneself through art, using paints or pencils, which is calming and fosters creativity.",
  },
  "Doing a DIY project for home decor": {
    group: "Creative and Crafty",
    activity: "Doing a DIY project for home decor",
    rating: "7",
    reasoning: "Depends on skill levels",
    emoji: "🛠️",
    description:
      "Working on a home improvement project together, which can be engaging and result in a practical output.",
  },
  "Watching a movie in the cinema": {
    group: "Entertainment and Festivities",
    activity: "Watching a movie in the cinema",
    rating: "3",
    reasoning: "No talking during the film",
    emoji: "🍿",
    description:
      "Watching a film on the big screen, which is a common social activity, though interaction is limited during the screening.",
  },
  "Going to a concert": {
    group: "Entertainment and Festivities",
    activity: "Going to a concert",
    rating: "5",
    reasoning: "Loud, hard to converse",
    emoji: "🎤",
    description:
      "Enjoying live music performances, which can be thrilling and energizing, though conversation is limited due to noise.",
  },
  "Exploring an amusement park": {
    group: "Entertainment and Festivities",
    activity: "Exploring an amusement park",
    rating: "8",
    reasoning: "Fun, active",
    emoji: "🎢",
    description:
      "Riding roller coasters, games, and other attractions, offering excitement and fun in an active setting.",
  },
  "Attending a festival or fair": {
    group: "Entertainment and Festivities",
    activity: "Attending a festival or fair",
    rating: "9",
    reasoning: "Fun, lots to see and do",
    emoji: "🎡",
    description:
      "Enjoying a local community or cultural festival, which offers food, entertainment, and activities, great for experiencing local culture.",
  },
  "Going to a karaoke bar": {
    group: "Entertainment and Festivities",
    activity: "Going to a karaoke bar",
    rating: "6",
    reasoning: "Fun, but can be intimidating",
    emoji: "🎤",
    description:
      "Singing or watching others perform songs, which can be a fun way to express oneself and enjoy music, though it might be intimidating for some.",
  },
  "Going on a ghost tour": {
    group: "Entertainment and Festivities",
    activity: "Going on a ghost tour",
    rating: "9",
    reasoning: "Exciting, unique",
    emoji: "👻",
    description:
      "Exploring reportedly haunted locations and hearing ghost stories, which can be thrilling and mysterious.",
  },
  "Exploring a farmer's market": {
    group: "Shopping and Exploring",
    activity: "Exploring a farmer's market",
    rating: "8",
    reasoning: "Casual, plenty to talk about",
    emoji: "🌽",
    description:
      "Visiting stalls that sell fresh produce, homemade goods, and crafts, offering a taste of local agriculture and artisanship.",
  },
  "Shopping at a flea market or antique shop": {
    group: "Shopping and Exploring",
    activity: "Shopping at a flea market or antique shop",
    rating: "9",
    reasoning: "Interesting finds, casual",
    emoji: "🛍️",
    description:
      "Hunting for unique and vintage finds, which can be enjoyable and reveal personal tastes and interests.",
  },
  "Exploring a new neighborhood in your city": {
    group: "Shopping and Exploring",
    activity: "Exploring a new neighborhood in your city",
    rating: "9",
    reasoning: "Adventure, lots to discuss",
    emoji: "🚶‍♂️",
    description:
      "Walking through different parts of town, discovering hidden gems and new places, which can be adventurous and educational.",
  },
  "Participating in a workshop or seminar": {
    group: "Learning and Growth",
    activity: "Participating in a workshop or seminar",
    rating: "6",
    reasoning: "Depends on the topic",
    emoji: "🏫",
    description:
      "Learning about a specific topic or skill in a structured setting, which can enhance knowledge and professional skills.",
  },
  "Joining a book club": {
    group: "Learning and Growth",
    activity: "Joining a book club",
    rating: "8",
    reasoning: "Great if everyone likes reading",
    emoji: "📚",
    description:
      "Reading and discussing books with a group, which can provide intellectual stimulation and deeper understanding of different perspectives.",
  },
  "Volunteering at a community event": {
    group: "Learning and Growth",
    activity: "Volunteering at a community event",
    rating: "9",
    reasoning: "Bonding through shared giving",
    emoji: "🤝",
    description:
      "Working together at a charity or community-driven event, which fosters a sense of giving back and bonding through shared efforts.",
  },
};
