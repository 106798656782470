import { SwapRightOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  ConfigProvider,
  Flex,
  Image,
  Layout,
  Row,
  Space,
  Typography,
} from "antd";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { Logo } from "./component/Container";
import Empty from "./empty.png";
import Friends from "./landing.png";

const strings = {
  landing: {
    // title: "Stop Using Tinder to Make Friends",
    title: "Lorem ipsum ",
    subtitle:
      "Make real connections offline with Jigsaur. Join now for genuine friendships that go beyond the screen.",
  },
  factors: [
    {
      title: "Your Genuine Friend-Finding App",
      subtitle: "Embrace Meetups and Genuine Friendships",
    },
    {
      title: "Where Real Connections Happen",
      subtitle: "Connecting You Offline for Meaningful Friendships",
    },
  ],
};

const { Header, Content, Footer } = Layout;

export default function LandingPage() {
  const navigate = useNavigate();
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgLayout: "white",
        },
      }}
    >
      <Layout style={{ height: "100%" }} id="container">
        <Header style={{ backgroundColor: "white" }}>
          <Flex
            justify="space-between"
            align="center"
            style={{ height: "100%" }}
          >
            <Logo />
            <Button type="primary" onClick={() => navigate("login")}>
              Log in
            </Button>
          </Flex>
        </Header>
        <Content style={{ padding: "0 48px", overflow: "scroll" }}>
          <Col style={{ maxWidth: "1280px" }}>
            <Alert
              message="Website in progress... Stay tuned!"
              type="error"
              style={{ margin: 10 }}
            />
            <Row align="middle" gutter={[8, 64]}>
              <Col xs={24} md={12}>
                <LandingInfos
                  {...strings.landing}
                  onClick={() => {
                    navigate("login");
                  }}
                  buttonText="Get Started"
                />
              </Col>
              <Col xs={24} md={12}>
                <Image width={"100%"} src={Friends} preview={false} />
              </Col>
            </Row>
          </Col>
          <Row align="middle">
            <Typography.Title>New site coming up!</Typography.Title>
          </Row>
          <Row align="middle" gutter={48}>
            <Col xs={24} md={12}>
              <Image width={"100%"} src={Empty} preview={false} />
            </Col>
            <Col xs={24} md={12}>
              <Space size={30} direction="vertical">
                {strings.factors.map((text, index) => (
                  <Factors {...text} key={index} icon={<SwapRightOutlined />} />
                ))}
              </Space>
            </Col>
          </Row>
          <Col style={{ marginTop: "60px" }}>
            <Typography.Paragraph type="secondary">
              See our Terms & Condition and Privacy Policy.
            </Typography.Paragraph>
            <Typography.Text>© Jigsaur Pty Ltd 2024</Typography.Text>
          </Col>
        </Content>
        <Footer></Footer>
      </Layout>
    </ConfigProvider>
  );
}

const LandingInfos = ({
  title,
  subtitle,
  onClick,
  buttonText,
}: {
  title: string;
  subtitle: string;
  onClick: () => void;
  buttonText: string;
}) => (
  <Space direction="vertical">
    <Space direction="vertical">
      <Typography.Title style={{ margin: 0 }}>{title}</Typography.Title>
      <Typography.Title level={4} type="secondary">
        {subtitle}
      </Typography.Title>
    </Space>
    <Space>
      <Button
        icon={<SwapRightOutlined />}
        type="primary"
        shape="round"
        size="large"
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </Space>
  </Space>
);

const Factors = ({
  title,
  subtitle,
  icon,
}: {
  title: string;
  subtitle: string;
  icon: ReactNode;
}) => (
  <Flex>
    <Space size="middle">
      <Button type="primary" icon={icon} size="large" />
      <Space direction="vertical">
        <Typography.Title level={4} style={{ margin: 0 }}>
          {title}
        </Typography.Title>
        <Typography.Text type="secondary">{subtitle}</Typography.Text>
      </Space>
    </Space>
  </Flex>
);
