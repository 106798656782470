import { User } from "firebase/auth";
import { doc, onSnapshot, runTransaction, setDoc } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { goals } from "../component/Form/ProfileInput";
import { AuthContext } from "./auth";
import { auth, db } from "./firebaseConfig";
import Loader from "component/Loader";

type ProfilePromise = {
  loading: boolean;
  error: any;
  profile?: Profile;
};

export type Profile = {
  firstName?: string;
  lastName?: string;
  location?: string;
  preferredName?: string;
  origin?: string;
  tags?: string[];
  email?: string;
  priorHobbies?: string;
  profilePic?: string;
  aboutMe?: string;
  verified?: boolean;
  password?: string;
  goal?: { [key in keyof typeof goals]: boolean };
};

export const UserContext = createContext<ProfilePromise>({
  loading: false,
  error: false,
});

function getProfileSnapshot<ProfilePromise>(
  user: User,
  success: (p: ProfilePromise) => void,
) {
  console.log("getting info");
  return onSnapshot(doc(db, "users", user.uid), (querySnapshot) => {
    if (querySnapshot.exists()) {
      const profile = querySnapshot.data() as ProfilePromise;
      success(profile);
    } else {
      createProfileIfNew(user);
    }
  });
}

export function UserProvider({ children }: { children: React.ReactNode }) {
  const authUser = useContext(AuthContext);
  const [userProfile, setUserProfile] = useState<ProfilePromise>({
    loading: true,
    error: false,
  });
  console.log(userProfile);
  useEffect(() => {
    if (authUser.loading) {
      // Firebase is still loading the user
      return;
    }
    if (!authUser.user) {
      // User is not logged in, no need to fetch profile
      setUserProfile({ loading: false, error: false });
      return;
    }

    return getProfileSnapshot(
      authUser.user,
      (profile: ProfilePromise["profile"] | undefined) =>
        setUserProfile({ profile, loading: false, error: false }),
    );
  }, [authUser]);

  if (userProfile.loading) {
    return (
      <Loader />
    );
  }

  return (
    <UserContext.Provider value={userProfile}>{children}</UserContext.Provider>
  );
}

export function createProfileIfNew(user: User) {
  if (!user) {
    throw Error();
  }
  const profileDoc = doc(db, "users", user.uid);
  return runTransaction(db, async (transaction) => {
    const sfDoc = await transaction.get(profileDoc);
    if (sfDoc.exists()) {
      return;
    }
    return transaction.set(profileDoc, {
      preferredName: user.displayName,
      email: user.email,
      profilePic: user.photoURL,
    });
  });
}

export function updateProfile(profile: Profile) {
  const user = auth.currentUser;
  if (!user) {
    throw Error();
  }
  console.log(profile);
  const profileDoc = doc(db, "users", user.uid);
  return setDoc(profileDoc, profile, { merge: true });
}
