import { Button, Flex, Image, Typography } from "antd";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { PlanContext, PlanType } from "./api/plan";
import { Container, DefaultHeader, ProfileAction } from "./component/Container";
import { PlanCard } from "./component/Plan";
import empty from "./empty.png";

const { Title, Paragraph } = Typography;

const Home = () => {
  const navigate = useNavigate();

  const plans = useContext(PlanContext);
  const planAvailable = plans?.plan?.length && plans.plan.length > 0;
  console.log(plans);

  return Container({
    header: <DefaultHeader action={<ProfileAction />} />,
    content: (
      <Flex align="stretch" style={{ height: "100%" }} vertical gap={30}>
        <Title level={2}>Welcome back!</Title>
        {planAvailable ? (
          plans.plan!.map((q) => (
            <PlanCard plan={q as unknown as PlanType} key={q?.id} />
          ))
        ) : (
          <Flex vertical flex={1} justify="center" align="center">
            <Image width={200} src={empty} preview={false} />
            <Paragraph>No teams yet, sign up for one now!</Paragraph>
          </Flex>
        )}
      </Flex>
    ),

    footer: (
      <Button
        type="primary"
        block
        shape="round"
        size="large"
        onClick={() => navigate("/createQueue")}
      >
        Start your great experience in Sydney!
      </Button>
    ),
  });
};
export default Home;
