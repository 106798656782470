import { Button, Flex, Form, Space, Steps, Typography } from "antd";
import { useForm } from "antd/es/form/Form";

import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Profile, updateProfile, UserContext } from "./api/user";
import { Container, DefaultHeader } from "./component/Container";
import { CardSelect, FormStage } from "./component/Form/Input";
import {
  AboutMe,
  GoalInput,
  OriginalLocation,
  PreferredName,
} from "./component/Form/ProfileInput";
const { Title, Text } = Typography;

const stages: FormStage<Profile>[] = [
  {
    title: "Let's Get Acquainted",
    subtitle:
      "We'd love to know a bit about you. Could you please share your name with us?",
    names: ["preferredName"],
    fields: (
      <>
        <PreferredName />
      </>
    ),
  },
  {
    title: "What are you looking for in Sydney?",
    subtitle: "Help us identify the right person for you to meet in Sydney!",
    names: ["goal"],
    fields: (
      <>
        <GoalInput />
      </>
    ),
  },
  {
    title: "Tell us more your interests",
    subtitle:
      "Telling us more about your interests will help us put you into groups that you like!",
    names: [],
    fields: (
      <>
        <Form.Item name="setupType" noStyle>
          <CardSelect
            multiple
            choices={[
              {
                id: "x",
                text: "Express set up",
                description: "Select from interest bundles.",
              },
              {
                id: "y",
                text: "Detailed set up",
                description: "Customize your interests.",
              },
            ]}
            size="small"
            onChange={(_) => {}}
            value={""}
          />
        </Form.Item>
      </>
    ),
  },
  {
    title: "Tell us more your interests",
    subtitle:
      "Telling us more about your interests will help us put you into groups that you like!",
    names: [],
    fields: (
      <>
        <Form.Item name="bundleSelection" noStyle>
          <CardSelect
            choices={[
              {
                id: "x",
                text: "Finance bro",
                description: "6\"5', Finance, Trust Fund, Blue Eyes",
              },
              {
                id: "y",
                text: "Sporty guy",
                description: "Sports sports all day",
              },
              {
                id: "k",
                text: "Foodie",
                description: "Food passion",
              },
              {
                id: "z",
                text: "Artist",
                description: "Draw, paint, cool, all the time",
              },
              {
                id: "l",
                text: "Gym bro",
                description: "Gym is my passion",
              },
            ]}
            size="small"
            onChange={(_) => {}}
            value={""}
          />
        </Form.Item>
      </>
    ),
  },
  {
    title: "Tell us more about yourself!",
    subtitle:
      "Everyone has a unique background that can spark great conversations. ",
    names: ["aboutMe", "location"],
    fields: (
      <>
        <AboutMe />
        <OriginalLocation />
      </>
    ),
  },
];

const CreateProfile = () => {
  const navigate = useNavigate();
  const [form] = useForm();

  const { profile } = useContext(UserContext);
  console.log(profile);
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    if (!profile) return;
    const completedStages = stages.findIndex(
      ({ names }) => !names.every((name) => profile[name]),
    );
    if (completedStages === -1) {
      // navigate("/");
      return;
    }
    setIndex(completedStages);
  }, [navigate]); // eslint-disable-line react-hooks/exhaustive-deps

  const { title, fields, subtitle = "" } = stages[index];

  return Container({
    header: <DefaultHeader />,
    content: (
      <Flex vertical gap={2}>
        <Steps
          size="small"
          current={index}
          items={stages.map(() => ({}))}
          direction="horizontal"
          responsive={false}
        />

        <Space.Compact direction="vertical" style={{ paddingBottom: 20 }}>
          <Title level={2}>{title}</Title>
          <Text type="secondary">{subtitle}</Text>
        </Space.Compact>
        <Form
          onValuesChange={() => {}}
          initialValues={profile}
          onFinish={(data) => {
            updateProfile(data as Profile);
            if (index + 1 < stages.length) {
              setIndex((p) => p + 1);
            } else {
              navigate("/");
            }
          }}
          form={form}
        >
          {fields}
        </Form>
      </Flex>
    ),
    footer: (
      <Flex vertical gap="large">
        <Button
          type="primary"
          block
          shape="round"
          size="large"
          onClick={() => {
            form.submit();
          }}
        >
          Next
        </Button>
      </Flex>
    ),
  });
};
export default CreateProfile;
