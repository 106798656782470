import { Button, Flex, Form, Space, Steps, Typography } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "./api/firebaseConfig";
import { createQueue } from "./api/queue";
import { Container, DefaultHeader } from "./component/Container";
import {
  ActivityDetailsInput,
  GroupSizeInput,
  ActivityInput,
} from "./component/Form/ActivityInput";
import {
  QueueTypeInput,
  ActivityLevelInput,
  DateTimeInput,
  PostInput,
  SameGenderOnlyInput,
  SuburbInput,
  VerifiedOnlyInput,
} from "./component/Form/QueueInput";
import {Timestamp} from "firebase/firestore";

const { Title, Text } = Typography;

const generalStage = [
  {
    title: "Choose your mode",
    subtitle: "Select what you are looking for",
    fields: (
      <>
        <QueueTypeInput />
      </>
    ),
  },
];

const hangoutStages = [
  {
    title: "Let's find some people!",
    subtitle: "Tell us more about when your ideal outing",
    fields: (
      <>
        <DateTimeInput />
        <SuburbInput label="Where would you prefer?" placeholder="Taronga" />
      </>
    ),
  },
  {
    title: "Preference",
    subtitle: "Preference is not taken into account in Beta. Coming soon!",
    fields: (
      <>
        <VerifiedOnlyInput />
        <SameGenderOnlyInput />
      </>
    ),
  },
];

const activityStages = [
  {
    title: "What do you want to do?",
    subtitle: "Let's find something to do with some people.",
    names: ["origin"],
    fields: (
      <>
        <ActivityInput />
        <ActivityDetailsInput disabled />
      </>
    ),
  },
  {
    title: "Start a hangout!",
    subtitle: "Let's find something to do with some people.",
    names: ["origin"],
    fields: (
      <>
        <ActivityInput disabled />
        <DateTimeInput />
        <SuburbInput
          label="Where are you looking to go?"
          placeholder="Taronga"
        />
      </>
    ),
  },
  {
    title: "Preference",
    subtitle:
      "The filters are designed to be inclusive and flexible, allowing users to explore a wide range of friendship opportunities while maintaining a safe and welcoming environment on Jigsaur. ",
    fields: (
      <>
        <GroupSizeInput />
        <VerifiedOnlyInput />
        <SameGenderOnlyInput />
        <ActivityLevelInput />
      </>
    ),
  },
  {
    title: "One last thing,",
    subtitle: "Write something that will maybe entice people to come!",
    fields: (
      <>
        <PostInput />
      </>
    ),
  },
];

const CreateQueue = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [index, setIndex] = useState<number>(0);

  const stages = [...generalStage];
  if (form.getFieldValue("specific") === "specific") {
    stages.splice(1, 0, ...activityStages);
  } else {
    stages.splice(1, 0, ...hangoutStages);
  }
  const { title, fields, subtitle = "" } = stages[index];

  return Container({
    header: <DefaultHeader />,
    content: (
      <Flex vertical gap={2}>
        <Steps
          size="small"
          current={index}
          items={stages.map((_, i) => ({ disabled: i > index }))}
          onChange={setIndex}
          direction="horizontal"
          responsive={false}
        />

        <Space direction="vertical">
          <Title level={2}>{title}</Title>
          <Text type="secondary">{subtitle}</Text>
          <Form
            onValuesChange={console.log}
            preserve
            onFinish={() => {
              const data = form.getFieldsValue(true);
              data.creator = auth.currentUser?.uid;
              console.log("Submitting", data, form.getFieldsValue(true));
              data.participants = [];
              if (index + 1 < stages.length) {
                setIndex((p) => p + 1);
              } else {
                if (data.preferredDate) {
                  data.preferredDate = Timestamp.fromDate(data.preferredDate.toDate())
                }
                createQueue(data);
                navigate("/");
              }
            }}
            form={form}
          >
            {fields}
          </Form>
        </Space>
      </Flex>
    ),
    footer: (
      <Flex vertical gap="large">
        <Button
          type="primary"
          block
          shape="round"
          size="large"
          onClick={() => {
            form.submit();
          }}
        >
          Next
        </Button>
      </Flex>
    ),
  });
};
export default CreateQueue;
