import {
  FacebookAuthProvider,
  getRedirectResult,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithRedirect,
  User as FirebaseUser,
} from "firebase/auth";

import { createContext, useEffect, useState } from "react";
import { auth } from "./firebaseConfig";

export type Status<T = {}> = {
  loading: boolean;
  error: any;
} & T;

export type AuthUser = Status<{ user?: FirebaseUser | null }>;

export const AuthContext = createContext<AuthUser>({
  loading: false,
  error: false,
});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [authUser, setAuthUser] = useState<AuthUser>({
    loading: true,
    user: auth.currentUser,
    error: false,
  });
  console.log(authUser);
  useEffect(
    () =>
      onAuthStateChanged(auth, (user) => {
        setAuthUser({ user, loading: false, error: false });
      }),
    [],
  );
  console.log(authUser);
  return (
    <AuthContext.Provider value={authUser}>{children}</AuthContext.Provider>
  );
}

export type AccountDetails = {
  email: string;
  password: string;
};

const provider = new FacebookAuthProvider();
provider.addScope("email");

export function loginViaFacebook() {
  return signInWithRedirect(auth, provider).then((result) => {
    console.log(result);
    const credential = FacebookAuthProvider.credentialFromResult(result);
    console.log(credential);
    const token = credential?.accessToken;
    console.log(token);
  });
  /*
  return signInAnonymously(auth).then(() => {
    window.location.pathname = "";
  });
   */
}

export function handleRedirect() {
  return getRedirectResult(auth).then((result) => {
    /*
    const user = result?.user as FirebaseUser;
    const credential =
      result && FacebookAuthProvider.credentialFromResult(result);
    const token = credential?.accessToken;
    if (token && user) {
      axios
        .get("https://graph.facebook.com/v19.0/me", {
          params: {
            fields: "id,name,age_range,email,first_name,last_name",
            access_token: token,
          },
        })
        .then((fbProfile) => {
          const { name, email, first_name, last_name } = fbProfile.data;
          createProfileIfNew(user, {
            email,
            preferredName: name,
            firstName: first_name,
            lastName: last_name,
            profilePic: user.photoURL ?? "",
          }).then(() => {
            window.location.path = "/createProfile"
          });
        });
    }
      */
  });
}

export function signInPassword({ email, password }: AccountDetails) {
  console.log(email, password);
  return signInWithEmailAndPassword(auth, email, password).catch((e) => {
    const errorMessages = {
      "auth/account-exists-with-different-credential":
        "An account with this email already exists. Please sign in using a different method.",
      "auth/invalid-credential": "Invalid password. Please try again.",
      "auth/operation-not-allowed":
        "This operation is not allowed. Please contact support for assistance.",
      "auth/user-disabled":
        "Your account has been disabled. Please contact support for assistance.",
      "auth/user-not-found":
        "No user found with this email address. Please sign up for an account.",
      "auth/wrong-password": "Incorrect password. Please try again.",
      "auth/invalid-verification-code":
        "The verification code entered is invalid. Please try again.",
      "auth/invalid-verification-id":
        "The verification ID is invalid. Please try again.",
      "auth/too-many-request":
        "You have tried too many times. Please try again later.",
    };
    type ErrorCodeType = keyof typeof errorMessages;
    throw new Error(errorMessages[e.code as ErrorCodeType]);
  });
}
