import { blue } from "@ant-design/colors";
import { FacebookFilled } from "@ant-design/icons";
import {
  Button,
  Card,
  ConfigProvider,
  Divider,
  Flex,
  Form,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AccountDetails,
  handleRedirect,
  loginViaFacebook,
  signInPassword,
  Status,
} from "./api/auth";
import { auth } from "./api/firebaseConfig";
import { Container, DefaultHeader, Logo } from "./component/Container";
import { EmailInput, PasswordInput } from "./component/Form/ProfileInput";
const { Title, Paragraph, Text } = Typography;

const Login = () => {
  const [status, setStatus] = useState<Status>({
    loading: false,
    error: false,
  });
  const submit = (credentials: AccountDetails) => {
    setStatus({ loading: true, error: false });
    signInPassword(credentials).catch((e) => {
      setStatus({ error: e.message, loading: false });
    });
  };
  const navigate = useNavigate();
  useEffect(() => {
    handleRedirect();
    if (auth.currentUser) {
      navigate("/");
    }
  }, [navigate]);

  return Container({
    lessPadding: true,
    header: <DefaultHeader />,
    content: (
      <Flex vertical gap={10} justify="center" style={{ height: "100%" }}>
        <Card>
          <Flex vertical gap={10}>
            <Flex justify="space-between">
              <Title level={2}>Welcome to Jigsaur</Title>
              <Logo size="small" />
            </Flex>
            <Paragraph>Come say hi!</Paragraph>
            <FacebookLogin />
            <Divider />
            <Text type="danger">{status.error}</Text>
            <Form onFinish={submit} hidden>
              <EmailInput />
              <PasswordInput />
              <Button type="primary" block htmlType="submit">
                Submit
              </Button>
            </Form>
            <Text type="secondary">
              Currently invite only, please login via Facebook to verify.
            </Text>
          </Flex>
        </Card>
      </Flex>
    ),
    footer: null,
  });
};
export default Login;

const FacebookLogin = () => {
  const [error, setError] = useState<Error | null>(null);
  const continueViaFacebook = () => {
    loginViaFacebook().catch(setError);
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: blue.primary,
        },
      }}
    >
      {error ? (
        <Text type="danger">
          Error occured, please try again. If this happens again, please contact
          us.
        </Text>
      ) : null}
      <Button
        block
        type="primary"
        size="large"
        icon={<FacebookFilled />}
        onClick={continueViaFacebook}
      >
        Continue with Facebook
      </Button>
    </ConfigProvider>
  );
};
