export const data = [
  {
    value: "abbotsbury",
    label: "Abbotsbury",
  },
  {
    value: "abbotsford",
    label: "Abbotsford",
  },
  {
    value: "acacia-gardens",
    label: "Acacia Gardens",
  },
  {
    value: "agnes-banks",
    label: "Agnes Banks",
  },
  {
    value: "airds",
    label: "Airds",
  },
  {
    value: "alexandria",
    label: "Alexandria",
  },
  {
    value: "alfords-point",
    label: "Alfords Point",
  },
  {
    value: "allambie-heights",
    label: "Allambie Heights",
  },
  {
    value: "allawah",
    label: "Allawah",
  },
  {
    value: "ambarvale",
    label: "Ambarvale",
  },
  {
    value: "annandale",
    label: "Annandale",
  },
  {
    value: "annangrove",
    label: "Annangrove",
  },
  {
    value: "arcadia",
    label: "Arcadia",
  },
  {
    value: "arncliffe",
    label: "Arncliffe",
  },
  {
    value: "arndell-park",
    label: "Arndell Park",
  },
  {
    value: "artarmon",
    label: "Artarmon",
  },
  {
    value: "ashbury",
    label: "Ashbury",
  },
  {
    value: "ashcroft",
    label: "Ashcroft",
  },
  {
    value: "ashfield",
    label: "Ashfield",
  },
  {
    value: "asquith",
    label: "Asquith",
  },
  {
    value: "auburn",
    label: "Auburn",
  },
  {
    value: "austral",
    label: "Austral",
  },
  {
    value: "avalon-beach",
    label: "Avalon Beach",
  },
  {
    value: "badgerys-creek",
    label: "Badgerys Creek",
  },
  {
    value: "balgowlah",
    label: "Balgowlah",
  },
  {
    value: "balgowlah-heights",
    label: "Balgowlah Heights",
  },
  {
    value: "balmain",
    label: "Balmain",
  },
  {
    value: "balmain-east",
    label: "Balmain East",
  },
  {
    value: "bangor",
    label: "Bangor",
  },
  {
    value: "banksia",
    label: "Banksia",
  },
  {
    value: "banksmeadow",
    label: "Banksmeadow",
  },
  {
    value: "bankstown",
    label: "Bankstown",
  },
  {
    value: "bankstown-aerodrome",
    label: "Bankstown Aerodrome",
  },
  {
    value: "barangaroo",
    label: "Barangaroo",
  },
  {
    value: "barden-ridge",
    label: "Barden Ridge",
  },
  {
    value: "bardia",
    label: "Bardia",
  },
  {
    value: "bardwell-park",
    label: "Bardwell Park",
  },
  {
    value: "bardwell-valley",
    label: "Bardwell Valley",
  },
  {
    value: "bass-hill",
    label: "Bass Hill",
  },
  {
    value: "baulkham-hills",
    label: "Baulkham Hills",
  },
  {
    value: "bayview",
    label: "Bayview",
  },
  {
    value: "beacon-hill",
    label: "Beacon Hill",
  },
  {
    value: "beaconsfield",
    label: "Beaconsfield",
  },
  {
    value: "beaumont-hills",
    label: "Beaumont Hills",
  },
  {
    value: "beecroft",
    label: "Beecroft",
  },
  {
    value: "belfield",
    label: "Belfield",
  },
  {
    value: "bella-vista",
    label: "Bella Vista",
  },
  {
    value: "bellevue-hill",
    label: "Bellevue Hill",
  },
  {
    value: "belmore",
    label: "Belmore",
  },
  {
    value: "belrose",
    label: "Belrose",
  },
  {
    value: "berala",
    label: "Berala",
  },
  {
    value: "berkshire-park",
    label: "Berkshire Park",
  },
  {
    value: "berowra",
    label: "Berowra",
  },
  {
    value: "berowra-creek",
    label: "Berowra Creek",
  },
  {
    value: "berowra-heights",
    label: "Berowra Heights",
  },
  {
    value: "berowra-waters",
    label: "Berowra Waters",
  },
  {
    value: "berrilee",
    label: "Berrilee",
  },
  {
    value: "beverley-park",
    label: "Beverley Park",
  },
  {
    value: "beverly-hills",
    label: "Beverly Hills",
  },
  {
    value: "bexley",
    label: "Bexley",
  },
  {
    value: "bexley-north",
    label: "Bexley North",
  },
  {
    value: "bidwill",
    label: "Bidwill",
  },
  {
    value: "bilgola-beach",
    label: "Bilgola Beach",
  },
  {
    value: "bilgola-plateau",
    label: "Bilgola Plateau",
  },
  {
    value: "birchgrove",
    label: "Birchgrove",
  },
  {
    value: "birrong",
    label: "Birrong",
  },
  {
    value: "blackett",
    label: "Blackett",
  },
  {
    value: "blacktown",
    label: "Blacktown",
  },
  {
    value: "blair-athol",
    label: "Blair Athol",
  },
  {
    value: "blairmount",
    label: "Blairmount",
  },
  {
    value: "blakehurst",
    label: "Blakehurst",
  },
  {
    value: "bligh-park",
    label: "Bligh Park",
  },
  {
    value: "bondi",
    label: "Bondi",
  },
  {
    value: "bondi-beach",
    label: "Bondi Beach",
  },
  {
    value: "bondi-junction",
    label: "Bondi Junction",
  },
  {
    value: "bonnet-bay",
    label: "Bonnet Bay",
  },
  {
    value: "bonnyrigg",
    label: "Bonnyrigg",
  },
  {
    value: "bonnyrigg-heights",
    label: "Bonnyrigg Heights",
  },
  {
    value: "bossley-park",
    label: "Bossley Park",
  },
  {
    value: "botany",
    label: "Botany",
  },
  {
    value: "bow-bowing",
    label: "Bow Bowing",
  },
  {
    value: "box-hill",
    label: "Box Hill",
  },
  {
    value: "bradbury",
    label: "Bradbury",
  },
  {
    value: "breakfast-point",
    label: "Breakfast Point",
  },
  {
    value: "brighton-le-sands",
    label: "Brighton-Le-Sands",
  },
  {
    value: "bringelly",
    label: "Bringelly",
  },
  {
    value: "bronte",
    label: "Bronte",
  },
  {
    value: "brooklyn",
    label: "Brooklyn",
  },
  {
    value: "brookvale",
    label: "Brookvale",
  },
  {
    value: "bundeena",
    label: "Bundeena",
  },
  {
    value: "bungarribee",
    label: "Bungarribee",
  },
  {
    value: "burraneer",
    label: "Burraneer",
  },
  {
    value: "burwood",
    label: "Burwood",
  },
  {
    value: "burwood-heights",
    label: "Burwood Heights",
  },
  {
    value: "busby",
    label: "Busby",
  },
  {
    value: "cabarita",
    label: "Cabarita",
  },
  {
    value: "cabramatta",
    label: "Cabramatta",
  },
  {
    value: "cabramatta-west",
    label: "Cabramatta West",
  },
  {
    value: "caddens",
    label: "Caddens",
  },
  {
    value: "cambridge-gardens",
    label: "Cambridge Gardens",
  },
  {
    value: "cambridge-park",
    label: "Cambridge Park",
  },
  {
    value: "camellia",
    label: "Camellia",
  },
  {
    value: "cammeray",
    label: "Cammeray",
  },
  {
    value: "campbelltown",
    label: "Campbelltown",
  },
  {
    value: "camperdown",
    label: "Camperdown",
  },
  {
    value: "campsie",
    label: "Campsie",
  },
  {
    value: "canada-bay",
    label: "Canada Bay",
  },
  {
    value: "canley-heights",
    label: "Canley Heights",
  },
  {
    value: "canley-vale",
    label: "Canley Vale",
  },
  {
    value: "canoelands",
    label: "Canoelands",
  },
  {
    value: "canterbury",
    label: "Canterbury",
  },
  {
    value: "caringbah",
    label: "Caringbah",
  },
  {
    value: "caringbah-south",
    label: "Caringbah South",
  },
  {
    value: "carlingford",
    label: "Carlingford",
  },
  {
    value: "carlton",
    label: "Carlton",
  },
  {
    value: "carnes-hill",
    label: "Carnes Hill",
  },
  {
    value: "carramar",
    label: "Carramar",
  },
  {
    value: "carss-park",
    label: "Carss Park",
  },
  {
    value: "cartwright",
    label: "Cartwright",
  },
  {
    value: "castle-cove",
    label: "Castle Cove",
  },
  {
    value: "castle-hill",
    label: "Castle Hill",
  },
  {
    value: "castlecrag",
    label: "Castlecrag",
  },
  {
    value: "castlereagh",
    label: "Castlereagh",
  },
  {
    value: "casula",
    label: "Casula",
  },
  {
    value: "catherine-field",
    label: "Catherine Field",
  },
  {
    value: "cattai",
    label: "Cattai",
  },
  {
    value: "cecil-hills",
    label: "Cecil Hills",
  },
  {
    value: "cecil-park",
    label: "Cecil Park",
  },
  {
    value: "centennial-park",
    label: "Centennial Park",
  },
  {
    value: "chatswood",
    label: "Chatswood",
  },
  {
    value: "chatswood-west",
    label: "Chatswood West",
  },
  {
    value: "cheltenham",
    label: "Cheltenham",
  },
  {
    value: "cherrybrook",
    label: "Cherrybrook",
  },
  {
    value: "chester-hill",
    label: "Chester Hill",
  },
  {
    value: "chifley",
    label: "Chifley",
  },
  {
    value: "chippendale",
    label: "Chippendale",
  },
  {
    value: "chipping-norton",
    label: "Chipping Norton",
  },
  {
    value: "chiswick",
    label: "Chiswick",
  },
  {
    value: "chullora",
    label: "Chullora",
  },
  {
    value: "church-point",
    label: "Church Point",
  },
  {
    value: "claremont-meadows",
    label: "Claremont Meadows",
  },
  {
    value: "clarendon",
    label: "Clarendon",
  },
  {
    value: "clareville",
    label: "Clareville",
  },
  {
    value: "claymore",
    label: "Claymore",
  },
  {
    value: "clemton-park",
    label: "Clemton Park",
  },
  {
    value: "clontarf",
    label: "Clontarf",
  },
  {
    value: "clovelly",
    label: "Clovelly",
  },
  {
    value: "clyde",
    label: "Clyde",
  },
  {
    value: "coasters-retreat",
    label: "Coasters Retreat",
  },
  {
    value: "cobbitty",
    label: "Cobbitty",
  },
  {
    value: "colebee",
    label: "Colebee",
  },
  {
    value: "collaroy",
    label: "Collaroy",
  },
  {
    value: "collaroy-plateau",
    label: "Collaroy Plateau",
  },
  {
    value: "colyton",
    label: "Colyton",
  },
  {
    value: "como",
    label: "Como",
  },
  {
    value: "concord",
    label: "Concord",
  },
  {
    value: "concord-west",
    label: "Concord West",
  },
  {
    value: "condell-park",
    label: "Condell Park",
  },
  {
    value: "connells-point",
    label: "Connells Point",
  },
  {
    value: "constitution-hill",
    label: "Constitution Hill",
  },
  {
    value: "coogee",
    label: "Coogee",
  },
  {
    value: "cottage-point",
    label: "Cottage Point",
  },
  {
    value: "cowan",
    label: "Cowan",
  },
  {
    value: "cranebrook",
    label: "Cranebrook",
  },
  {
    value: "",
    label: "",
  },
  {
    value: "cremorne",
    label: "Cremorne",
  },
  {
    value: "cremorne-point",
    label: "Cremorne Point",
  },
  {
    value: "cromer",
    label: "Cromer",
  },
  {
    value: "cronulla",
    label: "Cronulla",
  },
  {
    value: "crows-nest",
    label: "Crows Nest",
  },
  {
    value: "croydon",
    label: "Croydon",
  },
  {
    value: "croydon-park",
    label: "Croydon Park",
  },
  {
    value: "curl-curl",
    label: "Curl Curl",
  },
  {
    value: "currawong-beach",
    label: "Currawong Beach",
  },
  {
    value: "currans-hill",
    label: "Currans Hill",
  },
  {
    value: "daceyville",
    label: "Daceyville",
  },
  {
    value: "dangar-island",
    label: "Dangar Island",
  },
  {
    value: "darling-point",
    label: "Darling Point",
  },
  {
    value: "darlinghurst",
    label: "Darlinghurst",
  },
  {
    value: "darlington",
    label: "Darlington",
  },
  {
    value: "davidson",
    label: "Davidson",
  },
  {
    value: "dawes-point",
    label: "Dawes Point",
  },
  {
    value: "dean-park",
    label: "Dean Park",
  },
  {
    value: "dee-why",
    label: "Dee Why",
  },
  {
    value: "denham-court",
    label: "Denham Court",
  },
  {
    value: "denistone",
    label: "Denistone",
  },
  {
    value: "denistone-east",
    label: "Denistone East",
  },
  {
    value: "denistone-west",
    label: "Denistone West",
  },
  {
    value: "dharruk",
    label: "Dharruk",
  },
  {
    value: "dolans-bay",
    label: "Dolans Bay",
  },
  {
    value: "dolls-point",
    label: "Dolls Point",
  },
  {
    value: "doonside",
    label: "Doonside",
  },
  {
    value: "double-bay",
    label: "Double Bay",
  },
  {
    value: "dover-heights",
    label: "Dover Heights",
  },
  {
    value: "drummoyne",
    label: "Drummoyne",
  },
  {
    value: "duffys-forest",
    label: "Duffys Forest",
  },
  {
    value: "dulwich-hill",
    label: "Dulwich Hill",
  },
  {
    value: "dundas",
    label: "Dundas",
  },
  {
    value: "dundas-valley",
    label: "Dundas Valley",
  },
  {
    value: "dural",
    label: "Dural",
  },
  {
    value: "eagle-vale",
    label: "Eagle Vale",
  },
  {
    value: "earlwood",
    label: "Earlwood",
  },
  {
    value: "east-hills",
    label: "East Hills",
  },
  {
    value: "east-killara",
    label: "East Killara",
  },
  {
    value: "east-lindfield",
    label: "East Lindfield",
  },
  {
    value: "east-ryde",
    label: "East Ryde",
  },
  {
    value: "eastern-creek",
    label: "Eastern Creek",
  },
  {
    value: "eastgardens",
    label: "Eastgardens",
  },
  {
    value: "eastlakes",
    label: "Eastlakes",
  },
  {
    value: "eastwood",
    label: "Eastwood",
  },
  {
    value: "edensor-park",
    label: "Edensor Park",
  },
  {
    value: "edgecliff",
    label: "Edgecliff",
  },
  {
    value: "edmondson-park",
    label: "Edmondson Park",
  },
  {
    value: "elanora-heights",
    label: "Elanora Heights",
  },
  {
    value: "elderslie",
    label: "Elderslie",
  },
  {
    value: "elizabeth-bay",
    label: "Elizabeth Bay",
  },
  {
    value: "elizabeth-hills",
    label: "Elizabeth Hills",
  },
  {
    value: "elvina-bay",
    label: "Elvina Bay",
  },
  {
    value: "emerton",
    label: "Emerton",
  },
  {
    value: "enfield",
    label: "Enfield",
  },
  {
    value: "engadine",
    label: "Engadine",
  },
  {
    value: "englorie-park",
    label: "Englorie Park",
  },
  {
    value: "enmore",
    label: "Enmore",
  },
  {
    value: "epping",
    label: "Epping",
  },
  {
    value: "ermington",
    label: "Ermington",
  },
  {
    value: "erskine-park",
    label: "Erskine Park",
  },
  {
    value: "erskineville",
    label: "Erskineville",
  },
  {
    value: "eschol-park",
    label: "Eschol Park",
  },
  {
    value: "eveleigh",
    label: "Eveleigh",
  },
  {
    value: "fairfield",
    label: "Fairfield",
  },
  {
    value: "fairfield-east",
    label: "Fairfield East",
  },
  {
    value: "fairfield-heights",
    label: "Fairfield Heights",
  },
  {
    value: "fairfield-west",
    label: "Fairfield West",
  },
  {
    value: "fairlight",
    label: "Fairlight",
  },
  {
    value: "fiddletown",
    label: "Fiddletown",
  },
  {
    value: "five-dock",
    label: "Five Dock",
  },
  {
    value: "forest-glen",
    label: "Forest Glen",
  },
  {
    value: "forest-lodge",
    label: "Forest Lodge",
  },
  {
    value: "forestville",
    label: "Forestville",
  },
  {
    value: "frenchs-forest",
    label: "Frenchs Forest",
  },
  {
    value: "freshwater",
    label: "Freshwater",
  },
  {
    value: "galston",
    label: "Galston",
  },
  {
    value: "georges-hall",
    label: "Georges Hall",
  },
  {
    value: "gilead",
    label: "Gilead",
  },
  {
    value: "girraween",
    label: "Girraween",
  },
  {
    value: "gladesville",
    label: "Gladesville",
  },
  {
    value: "glebe",
    label: "Glebe",
  },
  {
    value: "gledswood-hills",
    label: "Gledswood Hills",
  },
  {
    value: "glen-alpine",
    label: "Glen Alpine",
  },
  {
    value: "glendenning",
    label: "Glendenning",
  },
  {
    value: "glenfield",
    label: "Glenfield",
  },
  {
    value: "glenhaven",
    label: "Glenhaven",
  },
  {
    value: "glenmore-park",
    label: "Glenmore Park",
  },
  {
    value: "glenorie",
    label: "Glenorie",
  },
  {
    value: "glenwood",
    label: "Glenwood",
  },
  {
    value: "gordon",
    label: "Gordon",
  },
  {
    value: "granville",
    label: "Granville",
  },
  {
    value: "grays-point",
    label: "Grays Point",
  },
  {
    value: "great-mackerel beach",
    label: "Great Mackerel Beach",
  },
  {
    value: "green-valley",
    label: "Green Valley",
  },
  {
    value: "greenacre",
    label: "Greenacre",
  },
  {
    value: "greendale",
    label: "Greendale",
  },
  {
    value: "greenfield-park",
    label: "Greenfield Park",
  },
  {
    value: "greenhills-beach",
    label: "Greenhills Beach",
  },
  {
    value: "greenwich",
    label: "Greenwich",
  },
  {
    value: "gregory-hills",
    label: "Gregory Hills",
  },
  {
    value: "greystanes",
    label: "Greystanes",
  },
  {
    value: "guildford",
    label: "Guildford",
  },
  {
    value: "guildford-west",
    label: "Guildford West",
  },
  {
    value: "gymea",
    label: "Gymea",
  },
  {
    value: "gymea-bay",
    label: "Gymea Bay",
  },
  {
    value: "haberfield",
    label: "Haberfield",
  },
  {
    value: "hammondville",
    label: "Hammondville",
  },
  {
    value: "harrington-park",
    label: "Harrington Park",
  },
  {
    value: "harris-park",
    label: "Harris Park",
  },
  {
    value: "hassall-grove",
    label: "Hassall Grove",
  },
  {
    value: "haymarket",
    label: "Haymarket",
  },
  {
    value: "heathcote",
    label: "Heathcote",
  },
  {
    value: "hebersham",
    label: "Hebersham",
  },
  {
    value: "heckenberg",
    label: "Heckenberg",
  },
  {
    value: "henley",
    label: "Henley",
  },
  {
    value: "hillsdale",
    label: "Hillsdale",
  },
  {
    value: "hinchinbrook",
    label: "Hinchinbrook",
  },
  {
    value: "hobartville",
    label: "Hobartville",
  },
  {
    value: "holroyd",
    label: "Holroyd",
  },
  {
    value: "holsworthy",
    label: "Holsworthy",
  },
  {
    value: "homebush",
    label: "Homebush",
  },
  {
    value: "homebush-west",
    label: "Homebush West",
  },
  {
    value: "horningsea-park",
    label: "Horningsea Park",
  },
  {
    value: "hornsby",
    label: "Hornsby",
  },
  {
    value: "hornsby-heights",
    label: "Hornsby Heights",
  },
  {
    value: "horsley-park",
    label: "Horsley Park",
  },
  {
    value: "hoxton-park",
    label: "Hoxton Park",
  },
  {
    value: "hunters-hill",
    label: "Hunters Hill",
  },
  {
    value: "huntingwood",
    label: "Huntingwood",
  },
  {
    value: "huntleys-cove",
    label: "Huntleys Cove",
  },
  {
    value: "huntleys-point",
    label: "Huntleys Point",
  },
  {
    value: "hurlstone-park",
    label: "Hurlstone Park",
  },
  {
    value: "hurstville",
    label: "Hurstville",
  },
  {
    value: "hurstville-grove",
    label: "Hurstville Grove",
  },
  {
    value: "illawong",
    label: "Illawong",
  },
  {
    value: "ingleburn",
    label: "Ingleburn",
  },
  {
    value: "ingleside",
    label: "Ingleside",
  },
  {
    value: "jamisontown",
    label: "Jamisontown",
  },
  {
    value: "jannali",
    label: "Jannali",
  },
  {
    value: "jordan-springs",
    label: "Jordan Springs",
  },
  {
    value: "kangaroo-point",
    label: "Kangaroo Point",
  },
  {
    value: "kareela",
    label: "Kareela",
  },
  {
    value: "kearns",
    label: "Kearns",
  },
  {
    value: "kellyville",
    label: "Kellyville",
  },
  {
    value: "kellyville-ridge",
    label: "Kellyville Ridge",
  },
  {
    value: "kemps-creek",
    label: "Kemps Creek",
  },
  {
    value: "kensington",
    label: "Kensington",
  },
  {
    value: "kenthurst",
    label: "Kenthurst",
  },
  {
    value: "kentlyn",
    label: "Kentlyn",
  },
  {
    value: "killara",
    label: "Killara",
  },
  {
    value: "killarney-heights",
    label: "Killarney Heights",
  },
  {
    value: "kings-langley",
    label: "Kings Langley",
  },
  {
    value: "kings-park",
    label: "Kings Park",
  },
  {
    value: "kingsford",
    label: "Kingsford",
  },
  {
    value: "kingsgrove",
    label: "Kingsgrove",
  },
  {
    value: "kingswood",
    label: "Kingswood",
  },
  {
    value: "kirkham",
    label: "Kirkham",
  },
  {
    value: "kirrawee",
    label: "Kirrawee",
  },
  {
    value: "kirribilli",
    label: "Kirribilli",
  },
  {
    value: "kogarah",
    label: "Kogarah",
  },
  {
    value: "kogarah-bay",
    label: "Kogarah Bay",
  },
  {
    value: "ku-ring-gai-chase",
    label: "Ku-ring-gai Chase",
  },
  {
    value: "kurnell",
    label: "Kurnell",
  },
  {
    value: "",
    label: "",
  },
  {
    value: "kurraba-point",
    label: "Kurraba Point",
  },
  {
    value: "kyeemagh",
    label: "Kyeemagh",
  },
  {
    value: "kyle-bay",
    label: "Kyle Bay",
  },
  {
    value: "la-perouse",
    label: "La Perouse",
  },
  {
    value: "lakemba",
    label: "Lakemba",
  },
  {
    value: "lalor-park",
    label: "Lalor Park",
  },
  {
    value: "lane-cove",
    label: "Lane Cove",
  },
  {
    value: "lane-cove north",
    label: "Lane Cove North",
  },
  {
    value: "lane-cove west",
    label: "Lane Cove West",
  },
  {
    value: "lansdowne",
    label: "Lansdowne",
  },
  {
    value: "lansvale",
    label: "Lansvale",
  },
  {
    value: "laughtondale",
    label: "Laughtondale",
  },
  {
    value: "lavender-bay",
    label: "Lavender Bay",
  },
  {
    value: "leets-vale",
    label: "Leets Vale",
  },
  {
    value: "leichhardt",
    label: "Leichhardt",
  },
  {
    value: "len-waters estate",
    label: "Len Waters Estate",
  },
  {
    value: "leppington",
    label: "Leppington",
  },
  {
    value: "lethbridge-park",
    label: "Lethbridge Park",
  },
  {
    value: "leumeah",
    label: "Leumeah",
  },
  {
    value: "lewisham",
    label: "Lewisham",
  },
  {
    value: "liberty-grove",
    label: "Liberty Grove",
  },
  {
    value: "lidcombe",
    label: "Lidcombe",
  },
  {
    value: "lilli-pilli",
    label: "Lilli Pilli",
  },
  {
    value: "lilyfield",
    label: "Lilyfield",
  },
  {
    value: "lindfield",
    label: "Lindfield",
  },
  {
    value: "linley-point",
    label: "Linley Point",
  },
  {
    value: "little-bay",
    label: "Little Bay",
  },
  {
    value: "liverpool",
    label: "Liverpool",
  },
  {
    value: "llandilo",
    label: "Llandilo",
  },
  {
    value: "loftus",
    label: "Loftus",
  },
  {
    value: "londonderry",
    label: "Londonderry",
  },
  {
    value: "long-point",
    label: "Long Point",
  },
  {
    value: "longueville",
    label: "Longueville",
  },
  {
    value: "lovett-bay",
    label: "Lovett Bay",
  },
  {
    value: "lower-portland",
    label: "Lower Portland",
  },
  {
    value: "lucas-heights",
    label: "Lucas Heights",
  },
  {
    value: "luddenham",
    label: "Luddenham",
  },
  {
    value: "lugarno",
    label: "Lugarno",
  },
  {
    value: "lurnea",
    label: "Lurnea",
  },
  {
    value: "macquarie-fields",
    label: "Macquarie Fields",
  },
  {
    value: "macquarie-links",
    label: "Macquarie Links",
  },
  {
    value: "macquarie-park",
    label: "Macquarie Park",
  },
  {
    value: "maianbar",
    label: "Maianbar",
  },
  {
    value: "malabar",
    label: "Malabar",
  },
  {
    value: "manly",
    label: "Manly",
  },
  {
    value: "manly-vale",
    label: "Manly Vale",
  },
  {
    value: "maraylya",
    label: "Maraylya",
  },
  {
    value: "marayong",
    label: "Marayong",
  },
  {
    value: "maroota",
    label: "Maroota",
  },
  {
    value: "maroubra",
    label: "Maroubra",
  },
  {
    value: "marrickville",
    label: "Marrickville",
  },
  {
    value: "marsden-park",
    label: "Marsden Park",
  },
  {
    value: "marsfield",
    label: "Marsfield",
  },
  {
    value: "mascot",
    label: "Mascot",
  },
  {
    value: "matraville",
    label: "Matraville",
  },
  {
    value: "mays-hill",
    label: "Mays Hill",
  },
  {
    value: "mccarrs-creek",
    label: "McCarrs Creek",
  },
  {
    value: "mcgraths-hill",
    label: "McGraths Hill",
  },
  {
    value: "mcmahons-point",
    label: "McMahons Point",
  },
  {
    value: "meadowbank",
    label: "Meadowbank",
  },
  {
    value: "melrose-park",
    label: "Melrose Park",
  },
  {
    value: "menai",
    label: "Menai",
  },
  {
    value: "menangle-park",
    label: "Menangle Park",
  },
  {
    value: "merrylands",
    label: "Merrylands",
  },
  {
    value: "merrylands-west",
    label: "Merrylands West",
  },
  {
    value: "middle-cove",
    label: "Middle Cove",
  },
  {
    value: "middle-dural",
    label: "Middle Dural",
  },
  {
    value: "middleton-grange",
    label: "Middleton Grange",
  },
  {
    value: "miller",
    label: "Miller",
  },
  {
    value: "millers-point",
    label: "Millers Point",
  },
  {
    value: "milperra",
    label: "Milperra",
  },
  {
    value: "milsons-passage",
    label: "Milsons Passage",
  },
  {
    value: "milsons-point",
    label: "Milsons Point",
  },
  {
    value: "minchinbury",
    label: "Minchinbury",
  },
  {
    value: "minto",
    label: "Minto",
  },
  {
    value: "minto-heights",
    label: "Minto Heights",
  },
  {
    value: "miranda",
    label: "Miranda",
  },
  {
    value: "mona-vale",
    label: "Mona Vale",
  },
  {
    value: "monterey",
    label: "Monterey",
  },
  {
    value: "moore-park",
    label: "Moore Park",
  },
  {
    value: "moorebank",
    label: "Moorebank",
  },
  {
    value: "morning-bay",
    label: "Morning Bay",
  },
  {
    value: "mortdale",
    label: "Mortdale",
  },
  {
    value: "mortlake",
    label: "Mortlake",
  },
  {
    value: "mosman",
    label: "Mosman",
  },
  {
    value: "mount-annan",
    label: "Mount Annan",
  },
  {
    value: "mount-colah",
    label: "Mount Colah",
  },
  {
    value: "mount-druitt",
    label: "Mount Druitt",
  },
  {
    value: "mount-kuring-gai",
    label: "Mount Kuring-Gai",
  },
  {
    value: "mount-lewis",
    label: "Mount Lewis",
  },
  {
    value: "mount-pritchard",
    label: "Mount Pritchard",
  },
  {
    value: "mount-vernon",
    label: "Mount Vernon",
  },
  {
    value: "mulgoa",
    label: "Mulgoa",
  },
  {
    value: "mulgrave",
    label: "Mulgrave",
  },
  {
    value: "narellan",
    label: "Narellan",
  },
  {
    value: "narellan-vale",
    label: "Narellan Vale",
  },
  {
    value: "naremburn",
    label: "Naremburn",
  },
  {
    value: "narrabeen",
    label: "Narrabeen",
  },
  {
    value: "narraweena",
    label: "Narraweena",
  },
  {
    value: "narwee",
    label: "Narwee",
  },
  {
    value: "nelson",
    label: "Nelson",
  },
  {
    value: "neutral-bay",
    label: "Neutral Bay",
  },
  {
    value: "newington",
    label: "Newington",
  },
  {
    value: "newport",
    label: "Newport",
  },
  {
    value: "newtown",
    label: "Newtown",
  },
  {
    value: "normanhurst",
    label: "Normanhurst",
  },
  {
    value: "north-balgowlah",
    label: "North Balgowlah",
  },
  {
    value: "north-bondi",
    label: "North Bondi",
  },
  {
    value: "north-curl curl",
    label: "North Curl Curl",
  },
  {
    value: "north-epping",
    label: "North Epping",
  },
  {
    value: "north-kellyville",
    label: "North Kellyville",
  },
  {
    value: "north-manly",
    label: "North Manly",
  },
  {
    value: "north-narrabeen",
    label: "North Narrabeen",
  },
  {
    value: "north-parramatta",
    label: "North Parramatta",
  },
  {
    value: "north-rocks",
    label: "North Rocks",
  },
  {
    value: "north-ryde",
    label: "North Ryde",
  },
  {
    value: "north-st marys",
    label: "North St Marys",
  },
  {
    value: "north-strathfield",
    label: "North Strathfield",
  },
  {
    value: "north-sydney",
    label: "North Sydney",
  },
  {
    value: "north-turramurra",
    label: "North Turramurra",
  },
  {
    value: "north-wahroonga",
    label: "North Wahroonga",
  },
  {
    value: "north-willoughby",
    label: "North Willoughby",
  },
  {
    value: "northbridge",
    label: "Northbridge",
  },
  {
    value: "northmead",
    label: "Northmead",
  },
  {
    value: "northwood",
    label: "Northwood",
  },
  {
    value: "norwest",
    label: "Norwest",
  },
  {
    value: "oakhurst",
    label: "Oakhurst",
  },
  {
    value: "oakville",
    label: "Oakville",
  },
  {
    value: "oatlands",
    label: "Oatlands",
  },
  {
    value: "oatley",
    label: "Oatley",
  },
  {
    value: "old-guildford",
    label: "Old Guildford",
  },
  {
    value: "old-toongabbie",
    label: "Old Toongabbie",
  },
  {
    value: "oran-park",
    label: "Oran Park",
  },
  {
    value: "orchard-hills",
    label: "Orchard Hills",
  },
  {
    value: "oxford-falls",
    label: "Oxford Falls",
  },
  {
    value: "oxley-park",
    label: "Oxley Park",
  },
  {
    value: "oyster-bay",
    label: "Oyster Bay",
  },
  {
    value: "paddington",
    label: "Paddington",
  },
  {
    value: "padstow",
    label: "Padstow",
  },
  {
    value: "padstow-heights",
    label: "Padstow Heights",
  },
  {
    value: "pagewood",
    label: "Pagewood",
  },
  {
    value: "palm-beach",
    label: "Palm Beach",
  },
  {
    value: "panania",
    label: "Panania",
  },
  {
    value: "parklea",
    label: "Parklea",
  },
  {
    value: "parramatta",
    label: "Parramatta",
  },
  {
    value: "peakhurst",
    label: "Peakhurst",
  },
  {
    value: "peakhurst-heights",
    label: "Peakhurst Heights",
  },
  {
    value: "pemulwuy",
    label: "Pemulwuy",
  },
  {
    value: "pendle-hill",
    label: "Pendle Hill",
  },
  {
    value: "pennant-hills",
    label: "Pennant Hills",
  },
  {
    value: "penrith",
    label: "Penrith",
  },
  {
    value: "penshurst",
    label: "Penshurst",
  },
  {
    value: "petersham",
    label: "Petersham",
  },
  {
    value: "phillip-bay",
    label: "Phillip Bay",
  },
  {
    value: "picnic-point",
    label: "Picnic Point",
  },
  {
    value: "pitt-town",
    label: "Pitt Town",
  },
  {
    value: "pleasure-point",
    label: "Pleasure Point",
  },
  {
    value: "plumpton",
    label: "Plumpton",
  },
  {
    value: "point-piper",
    label: "Point Piper",
  },
  {
    value: "port-botany",
    label: "Port Botany",
  },
  {
    value: "port-hacking",
    label: "Port Hacking",
  },
  {
    value: "potts-hill",
    label: "Potts Hill",
  },
  {
    value: "potts-point",
    label: "Potts Point",
  },
  {
    value: "prairiewood",
    label: "Prairiewood",
  },
  {
    value: "",
    label: "",
  },
  {
    value: "prestons",
    label: "Prestons",
  },
  {
    value: "prospect",
    label: "Prospect",
  },
  {
    value: "punchbowl",
    label: "Punchbowl",
  },
  {
    value: "putney",
    label: "Putney",
  },
  {
    value: "pymble",
    label: "Pymble",
  },
  {
    value: "pyrmont",
    label: "Pyrmont",
  },
  {
    value: "quakers-hill",
    label: "Quakers Hill",
  },
  {
    value: "queens-park",
    label: "Queens Park",
  },
  {
    value: "queenscliff",
    label: "Queenscliff",
  },
  {
    value: "raby",
    label: "Raby",
  },
  {
    value: "ramsgate",
    label: "Ramsgate",
  },
  {
    value: "ramsgate-beach",
    label: "Ramsgate Beach",
  },
  {
    value: "randwick",
    label: "Randwick",
  },
  {
    value: "redfern",
    label: "Redfern",
  },
  {
    value: "regents-park",
    label: "Regents Park",
  },
  {
    value: "regentville",
    label: "Regentville",
  },
  {
    value: "revesby",
    label: "Revesby",
  },
  {
    value: "revesby-heights",
    label: "Revesby Heights",
  },
  {
    value: "rhodes",
    label: "Rhodes",
  },
  {
    value: "richmond",
    label: "Richmond",
  },
  {
    value: "riverstone",
    label: "Riverstone",
  },
  {
    value: "riverview",
    label: "Riverview",
  },
  {
    value: "riverwood",
    label: "Riverwood",
  },
  {
    value: "rockdale",
    label: "Rockdale",
  },
  {
    value: "rodd-point",
    label: "Rodd Point",
  },
  {
    value: "rookwood",
    label: "Rookwood",
  },
  {
    value: "rooty-hill",
    label: "Rooty Hill",
  },
  {
    value: "ropes-crossing",
    label: "Ropes Crossing",
  },
  {
    value: "rose-bay",
    label: "Rose Bay",
  },
  {
    value: "rosebery",
    label: "Rosebery",
  },
  {
    value: "rosehill",
    label: "Rosehill",
  },
  {
    value: "roselands",
    label: "Roselands",
  },
  {
    value: "rosemeadow",
    label: "Rosemeadow",
  },
  {
    value: "roseville",
    label: "Roseville",
  },
  {
    value: "roseville-chase",
    label: "Roseville Chase",
  },
  {
    value: "rossmore",
    label: "Rossmore",
  },
  {
    value: "rouse-hill",
    label: "Rouse Hill",
  },
  {
    value: "royal-national park",
    label: "Royal National Park",
  },
  {
    value: "rozelle",
    label: "Rozelle",
  },
  {
    value: "ruse",
    label: "Ruse",
  },
  {
    value: "rushcutters-bay",
    label: "Rushcutters Bay",
  },
  {
    value: "russell-lea",
    label: "Russell Lea",
  },
  {
    value: "rydalmere",
    label: "Rydalmere",
  },
  {
    value: "ryde",
    label: "Ryde",
  },
  {
    value: "sackville-north",
    label: "Sackville North",
  },
  {
    value: "sadleir",
    label: "Sadleir",
  },
  {
    value: "sandringham",
    label: "Sandringham",
  },
  {
    value: "sandy-point",
    label: "Sandy Point",
  },
  {
    value: "sans-souci",
    label: "Sans Souci",
  },
  {
    value: "schofields",
    label: "Schofields",
  },
  {
    value: "scotland-island",
    label: "Scotland Island",
  },
  {
    value: "seaforth",
    label: "Seaforth",
  },
  {
    value: "sefton",
    label: "Sefton",
  },
  {
    value: "seven-hills",
    label: "Seven Hills",
  },
  {
    value: "shalvey",
    label: "Shalvey",
  },
  {
    value: "shanes-park",
    label: "Shanes Park",
  },
  {
    value: "silverwater",
    label: "Silverwater",
  },
  {
    value: "singletons-mill",
    label: "Singletons Mill",
  },
  {
    value: "smeaton-grange",
    label: "Smeaton Grange",
  },
  {
    value: "smithfield",
    label: "Smithfield",
  },
  {
    value: "south-coogee",
    label: "South Coogee",
  },
  {
    value: "south-granville",
    label: "South Granville",
  },
  {
    value: "south-hurstville",
    label: "South Hurstville",
  },
  {
    value: "south-maroota",
    label: "South Maroota",
  },
  {
    value: "south-penrith",
    label: "South Penrith",
  },
  {
    value: "south-turramurra",
    label: "South Turramurra",
  },
  {
    value: "south-wentworthville",
    label: "South Wentworthville",
  },
  {
    value: "south-windsor",
    label: "South Windsor",
  },
  {
    value: "spring-farm",
    label: "Spring Farm",
  },
  {
    value: "st-andrews",
    label: "St Andrews",
  },
  {
    value: "st-clair",
    label: "St Clair",
  },
  {
    value: "st-helens park",
    label: "St Helens Park",
  },
  {
    value: "st-ives",
    label: "St Ives",
  },
  {
    value: "st-ives chase",
    label: "St Ives Chase",
  },
  {
    value: "st-johns park",
    label: "St Johns Park",
  },
  {
    value: "st-leonards",
    label: "St Leonards",
  },
  {
    value: "st-marys",
    label: "St Marys",
  },
  {
    value: "st-peters",
    label: "St Peters",
  },
  {
    value: "stanhope-gardens",
    label: "Stanhope Gardens",
  },
  {
    value: "stanmore",
    label: "Stanmore",
  },
  {
    value: "strathfield",
    label: "Strathfield",
  },
  {
    value: "strathfield-south",
    label: "Strathfield South",
  },
  {
    value: "summer-hill",
    label: "Summer Hill",
  },
  {
    value: "surry-hills",
    label: "Surry Hills",
  },
  {
    value: "sutherland",
    label: "Sutherland",
  },
  {
    value: "sydenham",
    label: "Sydenham",
  },
  {
    value: "sydney",
    label: "Sydney",
  },
  {
    value: "sydney-olympic park",
    label: "Sydney Olympic Park",
  },
  {
    value: "sylvania",
    label: "Sylvania",
  },
  {
    value: "sylvania-waters",
    label: "Sylvania Waters",
  },
  {
    value: "tamarama",
    label: "Tamarama",
  },
  {
    value: "taren-point",
    label: "Taren Point",
  },
  {
    value: "telopea",
    label: "Telopea",
  },
  {
    value: "tempe",
    label: "Tempe",
  },
  {
    value: "tennyson-point",
    label: "Tennyson Point",
  },
  {
    value: "terrey-hills",
    label: "Terrey Hills",
  },
  {
    value: "the-ponds",
    label: "The Ponds",
  },
  {
    value: "the-rocks",
    label: "The Rocks",
  },
  {
    value: "thornleigh",
    label: "Thornleigh",
  },
  {
    value: "toongabbie",
    label: "Toongabbie",
  },
  {
    value: "tregear",
    label: "Tregear",
  },
  {
    value: "turramurra",
    label: "Turramurra",
  },
  {
    value: "turrella",
    label: "Turrella",
  },
  {
    value: "ultimo",
    label: "Ultimo",
  },
  {
    value: "varroville",
    label: "Varroville",
  },
  {
    value: "vaucluse",
    label: "Vaucluse",
  },
  {
    value: "villawood",
    label: "Villawood",
  },
  {
    value: "vineyard",
    label: "Vineyard",
  },
  {
    value: "voyager-point",
    label: "Voyager Point",
  },
  {
    value: "wahroonga",
    label: "Wahroonga",
  },
  {
    value: "waitara",
    label: "Waitara",
  },
  {
    value: "wakeley",
    label: "Wakeley",
  },
  {
    value: "wallacia",
    label: "Wallacia",
  },
  {
    value: "wareemba",
    label: "Wareemba",
  },
  {
    value: "warrawee",
    label: "Warrawee",
  },
  {
    value: "warriewood",
    label: "Warriewood",
  },
  {
    value: "warwick-farm",
    label: "Warwick Farm",
  },
  {
    value: "waterfall",
    label: "Waterfall",
  },
  {
    value: "waterloo",
    label: "Waterloo",
  },
  {
    value: "watsons-bay",
    label: "Watsons Bay",
  },
  {
    value: "wattle-grove",
    label: "Wattle Grove",
  },
  {
    value: "waverley",
    label: "Waverley",
  },
  {
    value: "waverton",
    label: "Waverton",
  },
  {
    value: "wedderburn",
    label: "Wedderburn",
  },
  {
    value: "wentworth-point",
    label: "Wentworth Point",
  },
  {
    value: "wentworthville",
    label: "Wentworthville",
  },
  {
    value: "werrington",
    label: "Werrington",
  },
  {
    value: "werrington-county",
    label: "Werrington County",
  },
  {
    value: "werrington-downs",
    label: "Werrington Downs",
  },
  {
    value: "west-hoxton",
    label: "West Hoxton",
  },
  {
    value: "west-pennant hills",
    label: "West Pennant Hills",
  },
  {
    value: "west-pymble",
    label: "West Pymble",
  },
  {
    value: "west-ryde",
    label: "West Ryde",
  },
  {
    value: "westleigh",
    label: "Westleigh",
  },
  {
    value: "westmead",
    label: "Westmead",
  },
  {
    value: "wetherill-park",
    label: "Wetherill Park",
  },
  {
    value: "whalan",
    label: "Whalan",
  },
  {
    value: "whale-beach",
    label: "Whale Beach",
  },
  {
    value: "wheeler-heights",
    label: "Wheeler Heights",
  },
  {
    value: "wiley-park",
    label: "Wiley Park",
  },
  {
    value: "willmot",
    label: "Willmot",
  },
  {
    value: "willoughby",
    label: "Willoughby",
  },
  {
    value: "willoughby-east",
    label: "Willoughby East",
  },
  {
    value: "windsor",
    label: "Windsor",
  },
  {
    value: "windsor-downs",
    label: "Windsor Downs",
  },
  {
    value: "winston-hills",
    label: "Winston Hills",
  },
  {
    value: "wisemans-ferry",
    label: "Wisemans Ferry",
  },
  {
    value: "wolli-creek",
    label: "Wolli Creek",
  },
  {
    value: "wollstonecraft",
    label: "Wollstonecraft",
  },
  {
    value: "woodbine",
    label: "Woodbine",
  },
  {
    value: "woodcroft",
    label: "Woodcroft",
  },
  {
    value: "woodpark",
    label: "Woodpark",
  },
  {
    value: "woollahra",
    label: "Woollahra",
  },
  {
    value: "woolloomooloo",
    label: "Woolloomooloo",
  },
  {
    value: "woolooware",
    label: "Woolooware",
  },
  {
    value: "woolwich",
    label: "Woolwich",
  },
  {
    value: "woronora",
    label: "Woronora",
  },
  {
    value: "woronora-heights",
    label: "Woronora Heights",
  },
  {
    value: "yagoona",
    label: "Yagoona",
  },
  {
    value: "yarrawarrah",
    label: "Yarrawarrah",
  },
  {
    value: "yennora",
    label: "Yennora",
  },
  {
    value: "yowie-bay",
    label: "Yowie Bay",
  },
  {
    value: "zetland",
    label: "Zetland",
  },
];

const availableSuburbes = [
  {
    value: "sydney",
    label: "Sydney CBD",
  },
  {
    value: "eastwood",
    label: "Eastwood",
  },
];
export default availableSuburbes;
