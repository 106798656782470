import { Button, Card, ConfigProvider, Flex, Form, Space, theme } from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { Timestamp } from "firebase/firestore";
import { useContext, useState } from "react";
import { FriendsContext } from "../../api/friends";
import { createPlan, PlanContext, PlanType } from "../../api/plan";
import { QueueContext, QueueType } from "../../api/queue";
import { Container, DefaultHeader } from "../../component/Container";
import { DateTimeInput, QueueTypeInput } from "../../component/Form/QueueInput";

const AdminPage = () => {
  const { queue } = useContext(QueueContext);
  const queueByDate: { [key: string]: QueueType[] } = {};
  const queueById: { [key: string]: QueueType } = {};

  queue?.forEach((q) => {
    if (!queueByDate[dayjs(q.preferredDate.toDate()).toString()]) {
      queueByDate[dayjs(q.preferredDate.toDate()).toString()] = [];
    }
    queueByDate[dayjs(q.preferredDate.toDate()).toString()].push(q);

    queueById[q.id] = q;
  });

  const [selectedQueues, addSelectedQueues] = useState<{
    [key: string]: boolean;
  }>({});

  const [form] = useForm();
  const selectQueues = (queue: QueueType) => {
    addSelectedQueues((prev) => ({ ...prev, [queue.id]: !prev[queue.id] }));
    form.setFieldValue("activityType", queue.activityType);
    form.setFieldValue("preferredDate", dayjs(queue.preferredDate.toDate()));
    form.setFieldValue("preferredTime", queue.preferredTime);
  };

  return Container({
    header: <DefaultHeader />,
    content: (
      <Flex justify="center" gap={10}>
        <Flex vertical>
          {Object.entries(queueByDate).map(([preferredDate, qs]) => (
            <Card title={preferredDate} key={preferredDate}>
              <Space wrap>
                {qs.map((q) => (
                  <PersonCard
                    key={q.id}
                    selected={selectedQueues[q.id]}
                    queue={q}
                    selectQueue={() => selectQueues(q)}
                  />
                ))}
              </Space>
            </Card>
          ))}
        </Flex>
        <Form
          form={form}
          onFinish={(data) => {
            const plan: Omit<PlanType, "id" | "join"> = {
              participants: Object.entries(selectedQueues)
                .filter(([qId, selected]) => selected)
                .map(([qId, selected]) => queueById[qId].creator),
              activity: data.activityType,
              time: data.preferredTime,
              date: Timestamp.fromDate(data.preferredDate.toDate()),
            };
            console.log(data);
            createPlan(plan);
          }}
        >
          <QueueTypeInput />
          <DateTimeInput />
          <Button htmlType="submit">Create Plan</Button>
        </Form>
      </Flex>
    ),
    footer: null,
  });
};
const PersonCard = ({
  queue,
  selectQueue,
  selected,
}: {
  queue: QueueType;
  selectQueue: () => void;
  selected: boolean;
}) => {
  const {
    token: { colorPrimaryBorder, colorTextLightSolid },
  } = theme.useToken();
  const selectedToken = {
    colorBorderSecondary: colorPrimaryBorder,
    colorTextHeading: colorTextLightSolid,
    colorFillAlter: "#43472c",
  };
  const profiles = useContext(FriendsContext);
  const { plan = [] } = useContext(PlanContext);
  return (
    <ConfigProvider
      key={queue.id}
      theme={{
        token: selected ? selectedToken : undefined,
      }}
    >
      <Card title={queue.activityType} type="inner" onClick={selectQueue}>
        {profiles[queue.creator]?.preferredName}
        <Card
          title={`Has ${
            plan.filter((x) => x.participants.some((y) => y === queue.creator))
              .length
          } upcoming plan`}
          type="inner"
          onClick={selectQueue}
        ></Card>
      </Card>
    </ConfigProvider>
  );
};

export default AdminPage;
