import { Form, Input, Select, Space } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { useState } from "react";
import { Profile } from "../../api/user";
import suburbsOptions from "../../data/suburbs";
import {
  CardSelect,
  CardSelectChoice,
  TextInput,
  removeKeyboardWhenScrollProps,
  textFilterOption,
} from "./Input";
import { required } from "./rules";

export const ProfileTextInput = ({
  name,
  label,
  placeholder,
  rules = [required],
  size = "large",
}: {
  name: keyof Profile;
  rules?: any[];
  placeholder?: string;
  label: string;
  size?: SizeType;
}) => (
  <Form.Item<Profile> name={name} rules={rules} label={label} initialValue="">
    <Input
      size={size}
      placeholder={placeholder}
      {...removeKeyboardWhenScrollProps}
    />
  </Form.Item>
);

export const FirstNameInput = () =>
  ProfileTextInput({ name: "firstName", label: "First Name" });

export const LastNameInput = () =>
  ProfileTextInput({ name: "lastName", label: "Last Name" });

export const PreferredName = () =>
  ProfileTextInput({ name: "preferredName", label: "Name" });

export const EmailInput = () =>
  ProfileTextInput({ name: "email", label: "Email", size: "small" });

export const PasswordInput = () => (
  <Form.Item<Profile> name="password" rules={[required]} label="Password">
    <Input.Password />
  </Form.Item>
);

export const PriorHobbies = () =>
  ProfileTextInput({
    name: "priorHobbies",
    label: "What are some things that you've spent a lot of time on?",
    rules: [],
  });

export const SuburbInput = ({
  label,
  placeholder,
}: {
  label?: string;
  placeholder?: string;
}) => {
  return (
    <Form.Item<Profile>
      label={label ?? "Your current location"}
      style={{ width: "100%" }}
      name="location"
      rules={[required]}
    >
      <Select
        size="large"
        showSearch
        style={{ width: "100%" }}
        {...removeKeyboardWhenScrollProps}
        placeholder={placeholder ?? "Your location"}
        optionFilterProp="children"
        filterOption={textFilterOption}
        options={suburbsOptions}
      />
    </Form.Item>
  );
};

export const OriginalLocation = ({ label }: { label?: string }) => (
  <Form.Item<Profile>
    label={label ?? "Where do you come from originally?"}
    style={{ width: "100%" }}
    name="origin"
    initialValue=""
  >
    <Input size="large" />
  </Form.Item>
);

export const goals = {
  friends: "🤝 Making new friends",
  network: "💼 Networking for professional opportunities",
  socialise: "🎉 Socializing and having fun",
  explore: "🎨 Exploring new hobbies or interests",
};

const goalChoices = (
  ["friends", "network", "socialise", "explore"] as const
).map((id) => ({ id, text: goals[id] }));

export const GoalInput = () => {
  return (
    <Form.Item<Profile> name="goal" noStyle>
      <CardSelect
        choices={goalChoices}
        onChange={(_) => {}}
        value={""}
        multiple
      />
    </Form.Item>
  );
};

export function AboutMe() {
  return (
    <Form.Item<Profile>
      rules={[required]}
      style={{ width: "100%" }}
      name="aboutMe"
      label="Write a short bio to let people know who you are"
    >
      <Input.TextArea placeholder="I am a person of interest" size="large" />
    </Form.Item>
  );
}

export function InterestInput() {
  const interestType = [
    {
      category: "Entertainment",
      subcategories: [
        "Movies",
        "Music",
        "Television Shows",
        "Books and Literature",
        "Theater and Performing Arts",
      ],
    },
    {
      category: "Hobbies and Crafts",
      subcategories: [
        "Reading",
        "Cooking and Baking",
        "Photography",
        "Painting and Drawing",
      ],
    },
    {
      category: "Sports and Fitness",
      subcategories: [
        "Participating in Sports",
        "Watching Sports",
        "Gym and Exercise",
        "Outdoor Activities  (e.g., hiking, camping)",
        "Martial Arts",
        "Running and Marathons",
      ],
    },
    {
      category: "Technology",
      subcategories: [
        "Consumer Electronics",
        "Software and Apps",
        "Video and Computer Games",
        "Robotics",
        "Virtual Reality and Augmented Reality",
      ],
    },
    {
      category: "Travel and Adventure",
      subcategories: [
        "Domestic Travel",
        "International Travel",
        "Adventure Activities",
        "Cultural Exploration",
        "Road Trips",
      ],
    },
    {
      category: "Food and Dining",
      subcategories: [
        "Trying New Cuisines",
        "Restaurant Reviews and Recommendations",
        "Gourmet Cooking",
        "Wine Tasting",
        "Food Blogging",
      ],
    },
    {
      category: "Fashion and Beauty",
      subcategories: [
        "Fashion Trends",
        "Beauty and Skincare",
        "Personal Grooming",
        "Makeup Artistry",
        "Jewelry and Accessories",
      ],
    },
    {
      category: "Social Issues and News",
      subcategories: [
        "Politics",
        "Environmental Issues",
        "Social Justice and Activism",
        "Current Events",
        "Human Rights",
      ],
    },
    {
      category: "Personal Development",
      subcategories: [
        "Self-Help and Motivation",
        "Meditation and Mindfulness",
        "Educational Pursuits",
        "Financial Planning and Investment",
      ],
    },
  ];
  const [active, setActive] = useState<string>("");
  return (
    <Form.Item name="activityType" noStyle>
      {active === "" ? (
        <Space direction="vertical" style={{ width: "100%" }}>
          {interestType.map(({ category }) => (
            <CardSelectChoice
              id={category}
              text={category}
              onSelect={() => setActive(category)}
            />
          ))}
        </Space>
      ) : (
        <Space direction="vertical" style={{ width: "100%" }}>
          {interestType
            .find((a) => a.category === active)!
            .subcategories.map((category) => (
              <CardSelectChoice
                id={category}
                text={category}
                onSelect={console.log}
              >
                <TextInput name="hello" rules={[]} label="try" />
              </CardSelectChoice>
            ))}
        </Space>
      )}
    </Form.Item>
  );
}

const nameToComponent = {
  aboutMe: <AboutMe />,
  goal: <GoalInput />,
  interests: <InterestInput />,
};
export default nameToComponent;
