import { Dayjs } from "dayjs";
import {
  collection,
  doc,
  onSnapshot,
  query,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { createContext, useEffect, useState } from "react";
import { AvailableActivity } from "../component/Form/QueueInput";
import { Status } from "./auth";
import { auth, db } from "./firebaseConfig";
import Loader from "component/Loader";

export function createQueue(queue: QueueType) {
  const user = auth.currentUser;
  if (!user) {
    throw Error();
  }
  const queueDoc = doc(db, "queue", user.uid);
  queue.creator = user.uid;
  return setDoc(queueDoc, queue, { merge: true });
}

function getQueueSnapshot(success: (p: QueueType[]) => void) {
  const q = query(collection(db, "queue"));
  return onSnapshot(q, (querySnapshot) => {
    const queues: QueueType[] = [];
    querySnapshot.forEach((doc) =>
      queues.push({ ...doc.data(), id: doc.id } as QueueType),
    );
    // queues.forEach((queue) => addJoinMethod(queue));
    success(queues);
  });
}

export type QueueType = {
  id: string;
  creator: string;
  preferredTime: "afternoon";
  preferredDate: Timestamp | Dayjs;
  post: string;
  options: FilterOptions;
  activityType: AvailableActivity;
};
export type ActivityType = {
  activityType: string;
  details: string;
  activity: string;
  groupSize: number;
  title: string;
  icon?: React.JSX.Element;
  id: string;
};
export type FilterOptions = {
  verifiedOnly: boolean;
  sameGenderOnly: boolean;
  level: "intermediate";
};

export const QueueContext = createContext<Status<{ queue?: QueueType[] }>>({
  loading: true,
  error: false,
});

export function QueueProvider({ children }: { children: React.ReactNode }) {
  const [queue, setQueue] = useState<Status<{ queue?: QueueType[] }>>({
    loading: true,
    error: false,
  });
  useEffect(() => {
    return getQueueSnapshot((queue: QueueType[]) =>
      setQueue({ queue, loading: false, error: false }),
    );
  }, []);

  console.log(queue);
  if (queue.loading) {
    return (
      <Loader />
    );
  }

  return (
    <QueueContext.Provider value={queue}>{children}</QueueContext.Provider>
  );
}
