import { SunOutlined, TeamOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Divider,
  Dropdown,
  Flex,
  Form,
  Input,
  Select,
  Space,
  Switch,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { QueueType } from "../../api/queue";
import { CardSelect, SuburbInput, textFilterOption } from "./Input";
import { required } from "./rules";

export const activities = {
  badminton: {
    icon: <SunOutlined style={{ fontSize: 50 }} />,
    text: "Badminton meet up",
    description: "Limited for 1 week only",
    id: "badminton",
  },
  coffeeCatchUp: {
    icon: <TeamOutlined style={{ fontSize: 50 }} />,
    text: "Coffee catch up",
    description: "",
    id: "coffeeCatchUp",
  },
};

export type AvailableActivity = keyof typeof activities;

export const QueueTypeInput = () => {
  const limitedChoices = ["badminton"].map(
    (x) => activities[x as AvailableActivity],
  );
  const classicChoices = ["coffeeCatchUp"].map(
    (x) => activities[x as AvailableActivity],
  );
  const suggestChoice = [
    {
      icon: <></>,
      text: "Suggest a new activity",
      id: "suggestNew",
    },
  ];

  return (
    <Flex vertical>
      <Typography.Title level={4}>Limited time mode</Typography.Title>
      <Form.Item<QueueType> name="activityType" noStyle>
        <CardSelect choices={limitedChoices} onChange={(_) => {}} value={""} />
      </Form.Item>
      <Divider />
      <Typography.Title level={4}>Classic meet mode</Typography.Title>
      <Space direction="vertical">
        <Form.Item<QueueType> name="activityType" noStyle>
          <CardSelect
            choices={classicChoices}
            onChange={(_) => {}}
            value={""}
          />
        </Form.Item>
        <Form.Item<QueueType> name="activityType" noStyle>
          <CardSelect
            choices={suggestChoice}
            onChange={(_) => {}}
            value={""}
            size="small"
          />
        </Form.Item>
      </Space>
    </Flex>
  );
};

export function DateTimeInput() {
  return (
    <Form.Item<QueueType> label="When are you free?" required>
      <Space>
        <Form.Item<QueueType> noStyle name="preferredDate" rules={[required]}>
          <DatePicker
            inputReadOnly={true}
            size="large"
            minDate={dayjs()}
            disabledDate={(current) => {
              if (current.day() === 6 || current.day() === 0) return false;
              return true;
            }}
            renderExtraFooter={() => "Note: Limited availability in Beta"}
          />
        </Form.Item>
        <Form.Item<QueueType> noStyle name="preferredTime" rules={[required]}>
          <TimeInput />
        </Form.Item>
      </Space>
    </Form.Item>
  );
}

function TimeInput({ value = [], onChange }: any) {
  const [open, setOpen] = useState<boolean>(false);
  let label: string = "";

  const times = {
    morning: "Morning",
    afternoon: "Afternoon",
    night: "Night",
    lateNight: "Late night",
  };

  switch (value?.length) {
    case undefined:
    case 0:
      label = "Select time";
      break;
    case 1:
      label = times[value[0] as keyof typeof times];
      break;
    default:
      label = `${value.length}+ time`;
  }
  return (
    <Dropdown
      menu={{
        items: [
          { label: "Morning", key: "morning" },
          { label: "Afternoon", key: "afternoon" },
          { label: "Night", key: "night" },
          { label: "Late night", key: "lateNight" },
        ],
        multiple: true,
        selectedKeys: value,
        selectable: true,
        onSelect: (info) => {
          console.log(info);
          onChange(info.selectedKeys);
        },
        onDeselect: (info) => {
          console.log(info);
          onChange(info.selectedKeys);
        },
      }}
      onOpenChange={(nextOpen, info) => {
        if (info.source === "trigger" || nextOpen) {
          setOpen(nextOpen);
        }
      }}
      open={open}
    >
      <Button size="large">{label}</Button>
    </Dropdown>
  );
}

export function LevelInput() {
  return (
    <Form.Item<QueueType>
      rules={[required]}
      label="Verified Only?"
      valuePropName="checked"
      name={["options", "level"]}
    >
      <Switch />
    </Form.Item>
  );
}

export function SameGenderOnlyInput() {
  return (
    <Form.Item<QueueType>
      label="Same Gender Only"
      valuePropName="checked"
      name={["options", "sameGenderOnly"]}
      initialValue={false}
    >
      <Switch />
    </Form.Item>
  );
}

export function VerifiedOnlyInput() {
  return (
    <Form.Item<QueueType>
      label="Verified Only?"
      valuePropName="checked"
      name={["options", "verifiedOnly"]}
      initialValue={false}
    >
      <Switch />
    </Form.Item>
  );
}

export function ActivityLevelInput({ label }: { label?: string }) {
  return (
    <Form.Item<QueueType>
      rules={[required]}
      label={label ?? "Are you looking for experience people?"}
      style={{ width: "100%" }}
      name={["options", "level"]}
    >
      <Select
        size="large"
        showSearch
        style={{ width: "100%" }}
        placeholder="Level"
        optionFilterProp="children"
        filterOption={textFilterOption}
        options={[
          { value: "none", label: "No preference" },
          { value: "beginner", label: "Beginner" },
          { value: "intermediate", label: "Intermediate" },
          { value: "expert", label: "Export" },
        ]}
      />
    </Form.Item>
  );
}

export function PostInput({ label }: { label?: string }) {
  const form = Form.useFormInstance();
  console.log(form.getFieldValue("specific"));
  if (!form.getFieldValue("specific")) {
    return <></>;
  }
  return (
    <Form.Item<QueueType>
      rules={[required]}
      style={{ width: "100%" }}
      name="post"
      label="Write an invitation to people who are coming!"
    >
      <Input.TextArea
        placeholder="Let's go to Taronga, I heard about this particular place!"
        size="large"
      />
    </Form.Item>
  );
}

export { SuburbInput };
