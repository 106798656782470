import {
  Card,
  Flex,
  Form,
  Input,
  Select,
  Space,
  Tag,
  theme,
  Typography,
} from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { useEffect, useState } from "react";
import suburbsOptions from "../../data/suburbs";
import { required } from "./rules";
const {  Title } = Typography;

const removeKeyboard = () => (document.activeElement as HTMLElement).blur();
export const removeKeyboardWhenScrollProps = {
  onFocus: () =>
    document
      .getElementById("root")!
      .addEventListener("touchmove", removeKeyboard),
  onBlur: () =>
    document
      .getElementById("root")!
      .removeEventListener("touchmove", removeKeyboard),
};

export type FormStage<FormField extends {}> = {
  title: string;
  subtitle: string;
  names: (keyof FormField)[];
  fields: React.JSX.Element;
};

export type InputType =
  | "firstName"
  | "lastName"
  | "password"
  | "username"
  | "email";

export const TextInput = ({
  name,
  label,
  placeholder,
  rules = [required],
  size = "large",
}: {
  name: string;
  rules?: any[];
  placeholder?: string;
  label: string;
  size?: SizeType;
}) => (
  <Form.Item name={name} rules={rules} label={label} initialValue="">
    <Input
      size={size}
      placeholder={placeholder}
      {...removeKeyboardWhenScrollProps}
    />
  </Form.Item>
);

export const textFilterOption = (
  input: string,
  option?: { label: string; value: string },
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

export const SuburbInput = ({
  label,
  placeholder,
}: {
  label?: string;
  placeholder?: string;
}) => {
  return (
    <Form.Item
      label={label ?? "Your current location"}
      style={{ width: "100%" }}
      name="location"
      rules={[required]}
    >
      <Select
        size="large"
        showSearch
        style={{ width: "100%" }}
        {...removeKeyboardWhenScrollProps}
        placeholder={placeholder ?? "Your location"}
        optionFilterProp="children"
        filterOption={textFilterOption}
        options={suburbsOptions}
      />
    </Form.Item>
  );
};
type CardChoiceProp = {
  icon?: React.ReactElement;
  id: string;
  text: string;
  description?: string;
};

export const CardSelect = ({
  value,
  onChange,
  choices,
  multiple,
  size = "default",
}: {
  multiple?: boolean;
  size?: "default" | "small";
  value: string | { [key: string]: boolean };
  onChange: (arg0: string | { [key: string]: boolean }) => void;
  choices: CardChoiceProp[];
}) => {
  console.log(value);
  useEffect(() => {
    if (multiple && typeof value !== "object") {
      onChange(choices.reduce((a, c) => ({ ...a, [c.id]: false }), {}));
    }
  }, [choices, multiple, onChange, value]);
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {choices.map(({ icon, text, id, description }, index) => (
        <CardSelectChoice
          id={id}
          icon={icon}
          text={text}
          key={id}
          description={description}
          selected={typeof value === "object" ? value[id] : value === id}
          onSelect={() =>
            typeof value === "object"
              ? onChange({ ...value, [id]: !value[id] })
              : onChange(id)
          }
        />
      ))}
    </Space>
  );
};
export const CardSelectChoice = ({
  icon,
  size = "default",
  text,
  selected,
  onSelect,
  description,
  children,
}: CardChoiceProp & {
  children?: React.JSX.Element;
  size?: "default" | "small";
  selected?: boolean;
  onSelect?: (...a: any) => void;
}) => {
  const {
    token: { colorPrimary },
  } = theme.useToken();
  return (
    <Card
      hoverable
      size={size}
      onClick={onSelect}
      bordered
      style={{
        border: selected ? `1px solid ${colorPrimary}` : "0",
      }}
    >
      <Space size="large" style={{ width: "100%" }} align="center">
        {icon}
        {size === "default" ? (
          <Flex vertical>
            <Title level={5} style={{ margin: 0 }}>
              {text}
            </Title>
            <Typography.Text style={{ margin: 0 }}>
              {description}
            </Typography.Text>
          </Flex>
        ) : (
          <Typography.Text style={{ margin: 0 }}>{text}</Typography.Text>
        )}
      </Space>
    {children}
    </Card>
  );
};

/*
 * TODO: Interests complete
type InterestsInputProps = {
  value?: Set<string>;
  onChange?: (value: Set<string>) => void;
};
 */

export const ActivityTitleInput = () =>
  TextInput({ name: "title", label: "Title" });

export function TagInput({
  onChange = () => {},
  tagsData,
}: {
  onChange?: Function;
  tagsData: string[];
}) {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const handleChange = (tag: string, checked: boolean) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
    onChange(nextSelectedTags);
  };

  return (
    <>
      {tagsData.map((tag) => (
        <Tag.CheckableTag
          key={tag}
          checked={selectedTags.includes(tag)}
          onChange={(checked) => handleChange(tag, checked)}
        >
          {tag}
        </Tag.CheckableTag>
      ))}
    </>
  );
}
